<div class="input-group mr-2">
  <div class="input-wrap">
    <input
      readonly
      [ngClass]="ngControl.valid ? 'ng-valid' : 'ng-invalid'"
      class="form-control"
      (blur)="inputBlur($event)"
      [ngModel]="dateString | localizedDate:'short'"
      (change)="onInputChange($event)"
      [disabled]="disabled"
    />
    <span *ngIf="dateString && clearable" class="pointer-element clear-button">
      <i class="fa fa-xmark" 
         (click)="clearInput()"></i>
    </span>
  </div>

	<div class="input-group-append">
		<button
      class="btn btn-outline-secondary"
      [ngbPopover]="calendarContent"
      container="body"
      [disabled]="disabled"
      type="button"
    >
      <i class="fa fa-calendar"></i>
    </button>
	</div>
</div>

<ng-template #calendarContent>
	<div>
		<div *ngIf="!showTimePickerToggle">
			<ngb-datepicker 
        id="dp" 
        #dp
        name="datepicker" 
        [startDate]="datetime.date" 
        [ngModel]="datetime.date"
				(ngModelChange)="onDateChange($event, dp)"
      ></ngb-datepicker>
			<button
        class="btn btn-block btn-outline-secondary w-100"
        [disabled]="!datetime.date.day"
        type="button"
        (click)="toggleDateTimeState($event)"
      >
        <i class="fa fa-clock"></i>
      </button>
		</div>
		<div *ngIf="showTimePickerToggle">
			<button
        class="btn btn-block btn-outline-secondary w-100"
        [ngbPopover]="calendarContent"
        container="body"
        type="button"
        (click)="toggleDateTimeState($event)"
      >
        <i class="fa fa-calendar"></i>
      </button>
			<div class="mt-auto">
				<ngb-timepicker #tp name="timepicker" [ngModel]="datetime.time" (ngModelChange)="onTimeChange($event)"
					[seconds]="seconds" [hourStep]="hourStep" [minuteStep]="minuteStep" [secondStep]="secondStep">
				</ngb-timepicker>
			</div>
		</div>
	</div>
</ng-template>

<!-- <pre>Model: {{ datetime | json }}</pre> -->
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import {
  and,
  filterOperatorType,
  FiltrableDataSource,
  greaterThanOrEqual,
  lessThanOrEqual
} from 'filtrable-data-source';
import { debounceTime } from 'rxjs/operators';
import {
  FiltersStorageService,
  FilterStorageState
} from '../filters-storage.service';
import { FiltersService } from '../filters.service';

@Component({
  selector: 'app-data-source-number-field',
  templateUrl: './data-source-number-field.component.html',
  styleUrls: ['./data-source-number-field.component.scss']
})
export class DataSourceNumberFieldComponent implements OnInit {
  @Input() dataSource: FiltrableDataSource<unknown>;
  @Input() field: string;
  @Input() placeholder: string;
  @Input() fieldControlStart = new UntypedFormControl(undefined);
  @Input() fieldControlEnd = new UntypedFormControl(undefined);
  @Input() applyOnChanges: boolean = true;
  operatorStart: filterOperatorType = 'greaterThanOrEqual';
  operatorEnd: filterOperatorType = 'lessThanOrEqual';

  constructor(
    private router: Router,
    private filtersStorageService: FiltersStorageService,
    private filtersService: FiltersService
  ) {}

  ngOnInit(): void {
    const DEBOUNCE_TIME = this.applyOnChanges ? 700 : 0;

    this.fieldControlStart.valueChanges
      .pipe(debounceTime(DEBOUNCE_TIME))
      .subscribe((val) => {
        const searchedValue = val || '';
        if (searchedValue) {
          this.addFilter('START');
        } else {
          this.removeFilter('START');
        }
      });

    this.fieldControlEnd.valueChanges
      .pipe(debounceTime(DEBOUNCE_TIME))
      .subscribe((val) => {
        const searchedValue = val || '';
        if (searchedValue) {
          this.addFilter('END');
        } else {
          this.removeFilter('END');
        }
      });
  }

  private addFilter(type: 'START' | 'END'): void {
    if (type === 'START') {
      if (this.fieldControlEnd.value) {
        this.dataSource.setFilter(
          this.field,
          and(
            greaterThanOrEqual(this.fieldControlStart.value),
            lessThanOrEqual(this.fieldControlEnd.value)
          )
        );
      } else {
        this.dataSource.setFilter(
          this.field,
          greaterThanOrEqual(this.fieldControlStart.value)
        );
      }
    } else {
      if (this.fieldControlStart.value) {
        this.dataSource.setFilter(
          this.field,
          and(
            greaterThanOrEqual(this.fieldControlStart.value),
            lessThanOrEqual(this.fieldControlEnd.value)
          )
        );
      } else {
        this.dataSource.setFilter(
          this.field,
          lessThanOrEqual(this.fieldControlEnd.value)
        );
      }
    }

    if (this.applyOnChanges) {
      this.dataSource.applyFilters();
    }

    this.saveFilterInStorage();
    this.filtersService.addFilters();
  }

  private removeFilter(type: 'START' | 'END'): void {
    if (type === 'START') {
      if (this.fieldControlEnd.value) {
        this.dataSource.setFilter(
          this.field,
          lessThanOrEqual(this.fieldControlEnd.value)
        );
      } else {
        this.dataSource.removeFilter(this.field);
        if (this.applyOnChanges) {
          this.dataSource.applyFilters();
        }
        this.filtersStorageService.removeItem(this.router.url, this.field);
        this.filtersService.removeFilters();
      }
    } else {
      if (this.fieldControlStart.value) {
        this.dataSource.setFilter(
          this.field,
          lessThanOrEqual(this.fieldControlStart.value)
        );
      } else {
        this.dataSource.removeFilter(this.field);
        if (this.applyOnChanges) {
          this.dataSource.applyFilters();
        }
        this.filtersStorageService.removeItem(this.router.url, this.field);
        this.filtersService.removeFilters();
      }
    }
  }

  private saveFilterInStorage(): void {
    const filterStateStart: FilterStorageState = {
      value: this.fieldControlStart.value,
      operator: this.operatorStart
    };
    const filterStateEnd: FilterStorageState = {
      value: this.fieldControlStart.value,
      operator: this.operatorEnd
    };
    this.filtersStorageService.setItem(
      this.router.url,
      this.field,
      filterStateStart
    );
    this.filtersStorageService.setItem(
      this.router.url,
      this.field,
      filterStateEnd
    );
  }
}

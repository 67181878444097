import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RecipeBoDtoNBK } from 'src/app/api/nbk';

@Component({
  selector: 'app-menu-recipe-item',
  templateUrl: './menu-recipe-item.component.html',
  styleUrls: ['./menu-recipe-item.component.scss']
})
export class MenuRecipeItemComponent implements OnInit {
  @Input() recipe: RecipeBoDtoNBK;

  @Output() onDelete: EventEmitter<RecipeBoDtoNBK> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  delete() {
    this.onDelete.emit(this.recipe);
  }

}

<div
  [style.backgroundImage]="'url(' + imageUrl + ')'"
  #imageBox
  *ngIf="otherId"
  [id]="otherId"
  [ngClass]="{ isDragging: isDragging }"
>
  <i
    *ngIf="(imageValue || imageUrl) && isEditing"
    class="fa fa-fw fa-times delete"
    (click)="deleteImage()"
  ></i>
  <div
    tabindex="0"
    *ngIf="!imageValue && !imageUrl && isEditing"
    (click)="image.click()"
    (keyup.enter)="image.click()"
    (dragover)="onDragOver($event)"
    (dragleave)="isDragging = false"
    (drop)="imageDropped($event)"
    [style.width]="'100%'"
    [style.height]="'100%'"
  >
    <i *ngIf="!imageValue && !imageUrl && isEditing" class="fa fa-pen"></i>
  </div>
</div>
<input
  #image
  type="file"
  accept="image/png, image/jpeg, image/gif, image/bmp, image/tiff, image/wbmp"
  class="d-none"
  (change)="imageUploadFromInput($event)"
/>

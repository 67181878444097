import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { indicatorItemList } from './double-indicator-selector.model';

@Component({
  selector: 'app-double-indicator-selector',
  templateUrl: './double-indicator-selector.component.html',
  styleUrls: ['./double-indicator-selector.component.scss']
})
export class DoubleIndicatorSelectorComponent implements OnInit {
  @Input() title: string;
  @Input() type: string;
  @Input() items: indicatorItemList;
  @Input() selectorControl: UntypedFormControl = new UntypedFormControl('', []);

  constructor() {}

  ngOnInit(): void {}

  changeValue(newValue: any): void {
    newValue !== this.selectorControl.value
      ? this.selectorControl.setValue(newValue)
      : null;
  }
}

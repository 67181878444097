<div class="modal-header">
  <h2>{{ 'DEVICES.DEVICE_CARD.MODALS.PARAMETER.TITLE' | translate }}</h2>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<app-parameter-device [deviceId]="deviceId"></app-parameter-device>
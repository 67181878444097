import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { RecipeBoDtoNBK } from '../api/nbk';
import { DeviceModelEnum } from './utils';
import { SelectableItem } from './device.utils';

export const prefixTranslationICS = 'RECIPE.ICS';
export const translationICS = [_('RECIPE.ICS.COMBI'), _('RECIPE.ICS.HSO')];

export const converterTempUnit = (value: number, unit: 'F' | 'C') => {
  const fromCelsiusToFahrenheit = (c: number) => {
    return Math.round((((c - 32) * 5) / 9) * 100) / 100;
  };
  const fromFahrenheitToCelsius = (f: number) => {
    return Math.round(((f * 9) / 5 + 32) * 100) / 100;
  };

  return unit === 'F'
    ? fromFahrenheitToCelsius(value)
    : fromCelsiusToFahrenheit(value);
};

export const  haveAllSameDeviceModel = (elements: RecipeBoDtoNBK[]): boolean => {
  if (elements.length === 0) return true;
  const firstModel = elements[0].deviceModel;
  return elements.every(r => r.deviceModel === firstModel);
}

export const getMultipleExportOptions = (elements: RecipeBoDtoNBK[]) => {
  const types : { [key in DeviceModelEnum]?: number }  = {};
  elements.forEach(r => {
    types[r.deviceModel!] = (types[r.deviceModel!] || 0) + 1;
  });

  let options: SelectableItem[] = []
  Object.keys(types).sort().forEach(element => {
    options.push({
      id: element as DeviceModelEnum,
      name: `DEVICE_MODEL.${element}`
    })
  });
  return options;
}

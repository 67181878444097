import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {
  @Output() confirmAction = new EventEmitter();
  @Output() cancelAction = new EventEmitter();

  title: string;
  message: string;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  confirm(): void {
    this.confirmAction.emit();
  }

  cancel() {
    this.activeModal.dismiss('cancel');
    this.cancelAction.emit();
  }
}

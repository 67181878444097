import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import { tap } from 'rxjs/operators';
import { AlarmEventNBK, TelemetryService } from 'src/app/api/nbk';
import { LocalizedDatePipe } from '../../pipes/localized-date.pipe';

@Component({
  selector: 'app-alarms-device',
  templateUrl: './alarms-device.component.html',
  styleUrls: ['./alarms-device.component.scss']
})
export class AlarmsDeviceComponent implements OnInit {
  @Input() deviceSerial: string;
  
  dataSource = new ClientFiltrableDataSource<AlarmEventNBK>();
  columns: TableColumn[] = [];

  @ViewChild('statusTpl', { static: true }) 
  statusTpl: ElementRef<HTMLElement>;

  constructor(
    private ts: TranslateService,
    private datePipe: LocalizedDatePipe,
    private telemetryService: TelemetryService
  ) {
    this.dataSource.limit = 15;
  }

  ngOnInit(): void {
    this.columns = [
      {
        width: 40,
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizeable: false,
        cellTemplate: this.statusTpl
      },
      {
        prop: 'event',
        name: this.ts.instant('GLOBAL.EVENT'),
      },
      {
        prop: 'startTimestamp',
        name: this.ts.instant('GLOBAL.START'),
        $$valueGetter: (e, prop) => {
          return this.datePipe.transform(e.startTimestamp, 'medium') || e.startTimestamp;
        },
      },
      {
        prop: 'endTimestamp',
        name: this.ts.instant('GLOBAL.END'),
        $$valueGetter: (e, prop) => {
          return this.datePipe.transform(e.endTimestamp, 'medium') || e.endTimestamp;
        },
      },
      {
        prop: 'duration',
        name: this.ts.instant('GLOBAL.DURATION'),
        $$valueGetter: (e, prop) => {
          return e.duration + "''";
        },
      }
    ];

    this.getAlarms();
  }

  getAlarms() {
    const dates = this.dateRange();
    this.telemetryService
      .getAlarmsList(this.deviceSerial, dates.start, dates.end)
      .pipe(
        tap((res) => {
          this.dataSource.setItems(res.data!);
        })
      )
      .subscribe();
  }

  private dateRange () {
    const end = new Date();
    end.setHours(23, 59, 59, 999);

    const previus30Days = new Date();
    previus30Days.setDate(previus30Days.getDate() - 30);
    previus30Days.setHours(23, 59, 59, 999);
    
    return { start: previus30Days.toISOString(), end: end.toISOString() }
    
  }

}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {
  /* IMAGE UPLOAD */
  @Input() imageUrl?: SafeStyle;
  @Input() isEditing?: boolean;
  @Input() otherId?: string;
  imageSizeCheckActive = false;
  imageTooBig = false;
  imageTooBigSize = false;

  @ViewChild('imageBox') imageBox: ElementRef<HTMLInputElement>;
  @ViewChild('image') image: ElementRef<HTMLInputElement>;
  isDragging = false;
  @Output() imageChanged = new EventEmitter<string>();
  @Output() imageChangedBlob = new EventEmitter<Blob | File | null>();
  imageValue: string | null = null;

  constructor() {}

  ngOnInit(): void {}

  imageDropped(event: DragEvent) {
    event.preventDefault();
    this.isDragging = false;

    if (event.dataTransfer && event.dataTransfer.files) {
      this.imageUpload(event.dataTransfer.files[0]);
    }
  }

  imageUploadFromInput(event: any) {
    this.imageUpload(event.target.files ? event.target.files[0] : null);
  }

  onDragOver(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.isDragging = true;
  }

  imageUpload(image: Blob | File | null) {
    this.imageTooBig = false;
    this.imageTooBigSize = false;

    if (image) {
      const reader = new FileReader();
      reader.onload = (re) => {
        const img = new Image();
        img.src = re.target?.result as string;
        img.onload = () => {
          const base64 = (reader.result as string).replace(
            /data:(.*);base64,/,
            ''
          );
          this.imageValue = base64;

          this.updateVisibleBgImage(`url(${re.target!.result})`);

          this.imageChanged.emit(base64);
          this.imageChangedBlob.emit(image);
        };
      };
      reader.readAsDataURL(image);
    }
  }

  updateVisibleBgImage(path: string) {
    this.imageBox.nativeElement.style.backgroundImage = path;
  }

  deleteImage() {
    this.imageUrl = undefined;
    this.imageValue = null;
    this.imageChanged.emit(undefined);
    this.imageChangedBlob.emit(undefined);
    this.image.nativeElement.value = '';
    this.updateVisibleBgImage('');
  }
}

<div class="settings-body row">

  <div class="col-12 col-lg-3 box">
    <div class="title">
      <label>{{ 'RECIPE.INFO' | translate }}</label>
    </div>
    <div class="content">
      <ul *ngIf="device">
        <li>
          <label>{{ 'DEVICES.DEVICE_CARD.SERIAL_NUMBER' | translate }}</label>
          <span>{{ device.serial || '--' }}</span>
        </li>
        <li>
          <label>{{ 'DEVICES.DEVICE_CARD.MODEL' | translate }}</label>
          <span>{{ device.model || '--' }}</span>
        </li>
        <li>
          <label>{{ 'DEVICES.DEVICE_CARD.HARDWARE_ID' | translate }}</label>
          <span>{{ device.hardwareId || '--' }}</span>
        </li>
        <li>
          <label>{{ 'DEVICES.DEVICE_CARD.FIRMWARE' | translate }}</label>
          <span>{{ device.firmware || '--' }}</span>
        </li>
        <li>
          <label>{{ 'DEVICES.DEVICE_CARD.SINC' | translate }}</label>
          <span>
            {{ device.lastSync ? (device.lastSync | fromUTCToLocale | date: 'medium':timezone:lang) : '--' }}
          </span>
        </li>
        <li>
          <label>{{ 'GLOBAL.LOGGED_USER' | translate }}</label>
          <span>
            {{ device.ownerUsername || '--' }}
          </span>
        </li>
        <li>
          <label>{{ 'DEVICES.DEVICE_CARD.REGISTRATION_DATE' | translate }}</label>
          <span>
            {{ device.currentOwnerFirstAssociation ? 
                (device.currentOwnerFirstAssociation | fromUTCToLocale | date: 'medium':timezone:lang) : '--' }}
          </span>
        </li>
      </ul>
    </div>
  </div>

  <div class="col-12 col-lg-3 box">
    <div class="title">
      <label>{{ 'GLOBAL.CONFIGURATIONS' | translate }}</label>
    </div>
    <div class="content">
      <ul *ngIf="device">
        <li>
          <label>{{ 'USER_DETAIL.PERSONAL_INFO.LANGUAGE' | translate }}</label>
          <span>{{ device.deviceConfiguration?.language || '--' }}</span>
        </li>
        <li>
          <label>{{ 'DEVICES.CONFIGURATION.KEYBOARD' | translate }}</label>
          <span>{{ device.deviceConfiguration?.keyboard || '--' }}</span>
        </li>
        <li *ngIf="ownerKcUser">
          <label>{{ 'USER_PREFERENCE.NABOOK_IS_MASTER' | translate }}</label>
          <span>{{ (ownerKcUser.nabookIsMaster ? 'GLOBAL.ENABLED' : 'GLOBAL.DISABLED') | translate }}</span>
        </li>
        <li *ngIf="ownerKcUser">
          <label>{{ 'USER_PREFERENCE.MULTIDISPLAY' | translate }}</label>
          <span>{{ (ownerKcUser.multiDisplay ? 'GLOBAL.ENABLED' : 'GLOBAL.DISABLED') | translate }}</span>
        </li>
      </ul>
    </div>
  </div>

</div>
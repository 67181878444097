<div class="modal-body">
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>

  <div id="recipe-actions-box">
    <img id="recipe-actions-image" [src]="getRecipeImage()" alt="Food" />
    <div id="recipe-actions">
      <div>
        <div ngbDropdown class="d-inline-block">
          <i ngbDropdownToggle class="fa fa-ellipsis-v"></i>
          <div ngbDropdownMenu>
            <button ngbDropdownItem (click)="viewRecipe()" *ngIf="canEdit()">
              {{ 'GLOBAL.EDIT' | translate }}
            </button>
            <button ngbDropdownItem (click)="copyRecipe()" *ngIf="!lainoxRecipe">
              {{ 'GLOBAL.DUPLICATE' | translate }}
            </button>
            <button
              ngbDropdownItem
              (click)="exportRecipe()"
              *ngIf="!lainoxRecipe"
            >
              {{ 'GLOBAL.EXPORT' | translate }}
            </button>
            <button
              class="red"
              ngbDropdownItem
              (click)="deleteRecipe()"
              *ngIf="canEdit()"
            >
              {{ 'GLOBAL.DELETE' | translate }}
            </button>
          </div>
        </div>
        <div id="recipe-title">{{ recipe.name }}</div>
      </div>
      <ul class="action-list">
        <li (click)="viewRecipe()">{{ 'RECIPES.ACTIONS.VIEW' | translate }}</li>
        <li (click)="addToMyDisplay()" *ngIf="!lainoxRecipe">
          {{ 'RECIPES.ACTIONS.ADD_TO_MYDISPLAY' | translate }}
        </li>
        <li (click)="addToMyNaboo()" *ngIf="lainoxRecipe">
          {{ 'RECIPES.ACTIONS.ADD_TO_MYNABOO' | translate }}
        </li>
        <li (click)="removeFromMyNaboo()" *ngIf="!lainoxRecipe && recipe.ics">
          {{ 'RECIPES.ACTIONS.REMOVE_FROM_MYNABOO' | translate }}
        </li>
        <!-- <li>{{ 'RECIPES.ACTIONS.ADD_TO_MENU' | translate }}</li>
        <li>{{ 'RECIPES.ACTIONS.ADD_TO_FOOD_COST' | translate }}</li>
        <li>{{ 'RECIPES.ACTIONS.ADD_TO_GROCERY_LIST' | translate }}</li> -->
      </ul>
    </div>
  </div>
</div>

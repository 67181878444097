import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { DeviceParametersDtoNBK, DeviceParamNBK, DeviceService } from 'src/app/api/nbk';
import { AuthService } from 'src/app/core/auth.service';
import { canServiceUserEdit } from 'src/app/core/permission.utils';
import { getTimeZone, KEY_LANGUAGE_STORAGE } from 'src/app/core/utils';

@Component({
  selector: 'app-parameter-device',
  templateUrl: './parameter-device.component.html',
  styleUrls: ['./parameter-device.component.scss']
})
export class ParameterDeviceComponent implements OnInit {
  @Input() deviceId: string;
  
  deviceParams: DeviceParametersDtoNBK;
  dataSource = new ClientFiltrableDataSource<DeviceParamNBK>();
  columns: TableColumn[] = [];

  lang: string;
  timezone: string;
  isSavingParams: boolean = false;
  isLoading: boolean = true;

  @ViewChild('valueTpl', { static: true }) 
  valueTpl: ElementRef<HTMLElement>;

  constructor(
    private ts: TranslateService,
    private toastrService: ToastrService,
    private deviceService: DeviceService,
    public authService: AuthService
  ) {
    this.dataSource.limit = 8;
  }

  ngOnInit(): void {
    this.lang = localStorage.getItem(KEY_LANGUAGE_STORAGE)!;
    this.timezone = getTimeZone();
    
    this.columns = [
      {
        prop: 'id',
        name: this.ts.instant('DEVICES.DEVICE_CARD.MODALS.PARAMETER.COLUMN.ID')
      },
      {
        prop: 'name',
        name: this.ts.instant('DEVICES.DEVICE_CARD.MODALS.PARAMETER.COLUMN.NAME')
      },
      {
        prop: 'value',
        name: this.ts.instant('DEVICES.DEVICE_CARD.MODALS.PARAMETER.COLUMN.VALUE'),
        cellTemplate: this.valueTpl
      },
      {
        prop: 'min',
        name: this.ts.instant('DEVICES.DEVICE_CARD.MODALS.PARAMETER.COLUMN.MIN')
      },
      {
        prop: 'max',
        name: this.ts.instant('DEVICES.DEVICE_CARD.MODALS.PARAMETER.COLUMN.MAX')
      },
      {
        prop: 'defValue',
        name: this.ts.instant('DEVICES.DEVICE_CARD.MODALS.PARAMETER.COLUMN.DEFAULT')
      },
    ];

    this.getParams();
  }

  getParams() {
    this.isLoading = true;
    this.deviceService
      .getDeviceParams(+this.deviceId!)
      .pipe(
        tap((res) => {
          this.deviceParams = res;
          this.dataSource.setItems(res.parameters || []);
        }),
        catchError((error) => {
          this.deviceParams = {
            deviceId: +this.deviceId
          };
          return of(null);
        }),
        finalize(() => this.isLoading = false)
      )
      .subscribe();
  }

  saveParams() {
    this.dataSource.items?.forEach( (elem, index) => {
      const param = this.deviceParams.parameters![index];
      param.value = elem.value;
    })

    this.isSavingParams = true;

    this.deviceService
      .setDeviceParams(+this.deviceId, this.deviceParams)
      .pipe(
        tap(() => {
          this.toastrService.success(
            this.ts.instant('DEVICES.DEVICE_CARD.MODALS.SAVE_PARAMS'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
        }),
        finalize(() => (this.isSavingParams = false))
      )
      .subscribe();
  }

  canEdit() {
    return canServiceUserEdit(this.authService);
  }

}

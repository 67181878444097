import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-settings-pin-modal',
  templateUrl: './settings-pin-modal.component.html',
  styleUrls: ['./settings-pin-modal.component.scss']
})
export class SettingsPinModal implements OnInit {
  @Input() currentPin: string = '';

  @Output() 
  insertPin: EventEmitter<string> = new EventEmitter();
  
  @Output() 
  validPin: EventEmitter<void> = new EventEmitter();

  settingsPinFormGroup: UntypedFormGroup;

  constructor(public activeModal: NgbActiveModal) {
    this.settingsPinFormGroup = new UntypedFormGroup({
      password: new UntypedFormControl(''),
      confirmPassword: new UntypedFormControl('')
    });
  }

  ngOnInit() {
    if (this.currentPin) {
      this.settingsPinFormGroup.get('password')?.setValidators([Validators.required]);
    } else {
      this.settingsPinFormGroup.get('password')?.setValidators([Validators.required, Validators.minLength(6)]);
      this.settingsPinFormGroup.get('confirmPassword')?.setValidators([Validators.required, this.passwordMatchValidator()]);
    }
  }

  passwordMatchValidator( ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = this.settingsPinFormGroup?.get('password')?.value;
      const confirmPassword = control.value;
      return password === confirmPassword ? null : { pinMismatch: true };
    };
  }

  handlePin() {
    if (this.settingsPinFormGroup.invalid) {
      return;
    }

    if ( this.currentPin && 
      this.settingsPinFormGroup.get('password')?.value !== this.currentPin ) {
      this.settingsPinFormGroup.get('password')?.setErrors({ wrongPin: true });
      return;
    }

    if ( !this.currentPin ) {
      const password = this.settingsPinFormGroup.get('password')?.value;
      this.insertPin.emit(password);
    } else {
      this.validPin.emit();
    }

    this.activeModal.close();
  }

}

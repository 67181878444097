import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import {
  ImageService,
  IngredientBoDtoNBK,
  IngredientTypeBoDtoNBK,
  IngredientTypeService,
  StepIngredientDtoNBK
} from 'src/app/api/nbk';
import { KEY_LANGUAGE_STORAGE, umList } from 'src/app/core/utils';
import { CreateIngredientModalComponent } from 'src/app/shared/create-ingredient-modal/create-ingredient-modal.component';
import {
  IngredientBoDtoNBKComplete,
  IngredientsService
} from '../../ingredients/ingredients.service';
import { EditStepIngredientModalComponent } from './edit-step-ingredient-modal/edit-step-ingredient-modal.component';
import { DatatableComponent } from 'src/app/shared/datatable/datatable/datatable.component';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent implements OnInit {
  @Input() step: UntypedFormGroup;
  @Input() index: number;
  @Input() isEditing: boolean;
  @Output() deleteStep = new EventEmitter();

  typeList: IngredientTypeBoDtoNBK[] = [];
  ingredientList: IngredientBoDtoNBK[] = [];
  ingredientListFiltered: IngredientBoDtoNBK[] = [];
  umList = umList;

  get stepIngredients() {
    return this.step.controls['stepIngredient'] as UntypedFormArray;
  }

  columns: TableColumn[];
  dataSource = new ClientFiltrableDataSource<StepIngredientDtoNBK>();

  ingredientFormGroup: UntypedFormGroup;

  @ViewChild('table', { static: true }) 
  table: DatatableComponent;

  @ViewChild('actionTpl', { static: true }) 
  actionTpl: ElementRef<HTMLElement>;

  @ViewChild('nameTpl', { static: true }) 
  nameTpl: ElementRef<HTMLElement>;

  @ViewChild('allergensTpl', { static: true })
  allergensTpl: ElementRef<HTMLElement>;

  stepImageUrl: string;

  constructor(
    private ts: TranslateService,
    private modalService: NgbModal,
    private ingredientsService: IngredientsService,
    private imageService: ImageService,
    private ingredientTypeService: IngredientTypeService
  ) {}

  ngOnInit(): void {
    this.resetIngredientForm();
    this.ingredientsService.ingredients$.subscribe(
      (ingr: IngredientBoDtoNBK[]) => {
        this.ingredientList = ingr;
        this.ingredientListFiltered = [...this.ingredientList];
        this.table.table.recalculate();
      }
    );

    this.ingredientTypeService
      .getAllIngredientTypes(localStorage.getItem(KEY_LANGUAGE_STORAGE)!)
      .subscribe((res) => {
        this.typeList = res.value || [];
        this.typeList = [
          ...[{ id: 0, name: this.ts.instant('INGREDIENT_TYPE.ALL') }],
          ...this.typeList
        ];
      });

    this.columns = [
      {
        name: this.ts.instant('RECIPE.INGREDIENT'),
        cellTemplate: this.nameTpl
      },
      {
        prop: 'quantity',
        name: this.ts.instant('RECIPE.QUANTITY')
      },
      {
        prop: 'um',
        name: this.ts.instant('RECIPE.UM')
      },
      {
        prop: 'calories',
        name: this.ts.instant('RECIPE.CALORIES')
      },
      {
        prop: 'allergens',
        name: this.ts.instant('RECIPE.ALLERGENS'),
        cellTemplate: this.allergensTpl
      },
      {
        prop: '',
        cellTemplate: this.actionTpl
      }
    ];
    this.dataSource.setItems(
      this.ingredientsService.getFullIngredients(
        this.stepIngredients.value || []
      )
    );

    const images = this.step.controls['images'].value;
    if (images && images.length > 0) {
      this.stepImageUrl = images[0].url;
    }

    this.ingredientFormGroup.controls['id'].valueChanges.subscribe((v) => {
      if (v) {
        const ingredientSelected = this.ingredientList.find((i) => i.id === v);
        this.ingredientFormGroup.controls['um'].setValue(
          ingredientSelected?.um
        );
      }
    });
    this.ingredientFormGroup.controls['type'].valueChanges.subscribe((v) => {
      this.ingredientFormGroup.controls['id'].reset();
      this.ingredientFormGroup.controls['um'].reset();

      if (v) {
        const newIngredients = this.ingredientList.filter(
          (i) => i.type?.id === v
        );
        this.ingredientListFiltered = [...newIngredients];
      } else {
        this.ingredientListFiltered = [...this.ingredientList];
      }
    });
  }

  iconChanged(file: File | Blob | null) {
    let oldId;
    if (
      this.step.controls['images'].value &&
      this.step.controls['images'].value.length > 0
    ) {
      oldId = this.step.controls['images'].value[0].id;
    }

    if (!file && oldId) {
      if (this.isEditing) {
        this.removeImage(oldId);
      }
    }
    (this.step.controls['images'] as UntypedFormArray).clear();
    (this.step.controls['images'] as UntypedFormArray).push(
      new UntypedFormGroup({
        alt: this.step.controls['name'],
        file: new UntypedFormControl(file),
        id: new UntypedFormControl('')
      })
    );
  }

  addIngredient() {
    if (this.ingredientFormGroup.valid) {
      this.stepIngredients.push(
        new UntypedFormGroup({
          ingredientId: new UntypedFormControl(this.ingredientFormGroup.value.id),
          quantity: new UntypedFormControl(this.ingredientFormGroup.value.quantity),
          um: new UntypedFormControl(this.ingredientFormGroup.value.um)
        })
      );

      this.reloadDatasource();
      this.resetIngredientForm();
    }
  }

  resetIngredientForm() {
    this.ingredientFormGroup = new UntypedFormGroup({
      type: new UntypedFormControl(0),
      id: new UntypedFormControl('', Validators.required),
      notes: new UntypedFormControl(''),
      quantity: new UntypedFormControl('', Validators.required),
      um: new UntypedFormControl('', Validators.required)
    });
  }

  createNewIngredient() {
    const modalRef = this.modalService.open(CreateIngredientModalComponent, {
      backdrop: 'static',
      modalDialogClass: 'modal-md'
    });
    modalRef.componentInstance.ingredientCreated.subscribe((res: IngredientBoDtoNBK) => {
      this.ingredientsService.getIngredients();
    });
  }

  editIngredient(ingredient: IngredientBoDtoNBKComplete, index: number) {
    const modalRef = this.modalService.open(EditStepIngredientModalComponent, {
      modalDialogClass: 'modal-md'
    });
    modalRef.componentInstance.ingredient = ingredient;
    modalRef.componentInstance.ingredientEdited.subscribe((v: UntypedFormGroup) => {
      modalRef.close();
      this.stepIngredients.removeAt(index);
      this.stepIngredients.setControl(
        index,
        new UntypedFormGroup({
          ingredientId: new UntypedFormControl(v.value.id),
          quantity: new UntypedFormControl(v.value.quantity),
          um: new UntypedFormControl(v.value.um)
        })
      );

      this.reloadDatasource();
    });
  }

  private reloadDatasource() {
    this.dataSource.setItems(
      this.ingredientsService.getFullIngredients(this.stepIngredients.value)
    );
  }

  deleteIngredient(index: number) {
    this.stepIngredients.removeAt(index);
    this.reloadDatasource();
  }

  removeImage(id: number) {
    this.imageService.deleteImage(id).subscribe();
  }
}

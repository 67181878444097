<div *ngIf="isEditing" id="accessories-filters">
  <div id="find-accessory">
    <label class="custom-label"
      >{{ 'RECIPE.FIND_ACCESSORIES' | translate }}

      <ng-select
        [items]="accessoryList"
        [formControl]="accessoryControl"
        classList="custom-input"
        bindLabel="name"
        autofocus
        [placeholder]="'RECIPE.ACCESSORY_NAME' | translate"
      >
      </ng-select>
    </label>
    <button class="button-primary" (click)="addAccessory()">
      <i class="fa fa-plus"></i>
    </button>
  </div>

  <button
    class="button-primary"
    *ngIf="canHandle()"
    (click)="createNewAccessory()"
  >
    <i class="fa fa-plus"> </i>{{ 'RECIPE.CREATE_NEW_ACCESSORY' | translate }}
  </button>
</div>
<div id="accessories-list">
  <p class="my-3" *ngIf="controlValue.value.length === 0">
    {{ 'ACCESSORY.NO_ACCESSORIES' | translate }}
  </p>
  <div
    class="single-accessory"
    *ngFor="let acc of controlValue.value; let i = index"
  >
    <div
      class="single-accessory-image"
      [style.backgroundImage]="
        'url(' + acc.images[0]?.url || 'assets/images/' + ')'
      "
    >
      <i
        (click)="removeAccessory(i)"
        class="fa fa-fw fa-times"
        *ngIf="isEditing"
      ></i>
    </div>
    <div class="single-accessory-text">
      {{ acc.name }}
    </div>
  </div>
</div>

<ng-container>
  <div class="row mx-0">
    <div class="col-12 section-box">
      <div class="switch-icon-box">
        <div class="icon-box">
          <img 
            [ngClass]="{'selected': !switcher.useDeltaTemperature}"
            src="assets/images/temp.png" alt="Time" (click)="setUseDeltaTemperature(false)" />
          </div>
          <div class="icon-box">
            <img 
            *ngIf="switcher.useSpillone"
            [ngClass]="{'selected': switcher.useDeltaTemperature}"
            src="assets/images/temp-del.png" alt="Spillpne" (click)="setUseDeltaTemperature(true)" />
        </div>
      </div>
      <div
        *ngIf="!switcher.useDeltaTemperature"
      >
        <span *ngIf="onlyForNaboo" class="note">*{{ 'COOKINGSTEP.RUN_ONLY_FOR_NABOO' | translate }}</span>
        <app-slider
          [control]="tempControl"
          [tempUnit]="tempUnit"
          [temperature]="true"
          [ceil]="temperatureSetup.ceil!"
          [floor]="temperatureSetup.floor"
          [minLimit]="temperatureSetup.minLimit"
          [color]="'red'"
          [step]="1"
          [showButton]="true"
          (valueChanged)="onTemperatureChage($event)"
        ></app-slider>
      </div>
      <div
        *ngIf="switcher.useDeltaTemperature"
      >
        <app-slider
          [control]="deltaTempControl"
          [tempUnit]="tempUnit"
          [temperature]="true"
          [ceil]="deltaTempSetup.ceil!"
          [floor]="deltaTempSetup.floor"
          [minLimit]="deltaTempSetup.minLimit"
          [color]="'red'"
          [step]="1"
          [showButton]="true"
        ></app-slider>
      </div>
    </div>
    <div class="col-12 section-box" 
      *ngIf="showControl(sectionTypeList.Time)"
    >
      <div class="switch-icon-box">
        <div class="icon-box">
          <img 
            [ngClass]="{'selected': switcher.useSpillone}"
            src="assets/images/nabook/cm_btn_sonda_temp.png" alt="Spillpne" (click)="setUseSpillone(true)" />
        </div>
        <div class="icon-box">
          <img 
            [ngClass]="{ 'selected': !switcher.useSpillone}"
            src="assets/images/time.png" alt="Time" (click)="setUseSpillone(false)" />
        </div>
      </div>
      <div>
        <app-slider
          *ngIf="!switcher.useSpillone"
          [control]="timeControl"
          [ceil]="timeSetup.ceil"
          [floor]="timeSetup.floor"
          [step]="timeSetup.step"
          [color]="'orange'"
          [time]="timeSetup.time"
          [showButton]="true"
          [firstStep]="timeSetup.firstStep"
        ></app-slider>
        <app-slider
          *ngIf="switcher.useSpillone"
          [control]="spilloneTempControl"
          [tempUnit]="tempUnit"
          [temperature]="true"
          [ceil]="spilloneSetup.ceil!"
          [floor]="spilloneSetup.floor"
          [minLimit]="spilloneSetup.minLimit"
          [color]="'orange'"
          [step]="1"
          [showButton]="true"
        ></app-slider>
      </div>
    </div>
    <div class="col-12 section-box"
      *ngIf="showControl(sectionTypeList.Autoclimate)"
    >
      <div class="icon-box">
        <img src="assets/images/nabook/cm_btn_autoclima.png" alt="autoclimate" class="selected" />
      </div>
      <div>
        <app-slider
          [control]="autoclimateControl"
          [ceil]="autoclimateSetup.ceil"
          [floor]="autoclimateSetup.floor"
          [step]="autoclimateSetup.step"
          [color]="'frozen'"
          [percentage]="true"
          [showButton]="true"
          [firstStep]="autoclimateSetup.firstStep"
        ></app-slider>
      </div>
    </div>
    <div class="col-12 section-box"
    *ngIf="showControl(sectionTypeList.VaporType)"
  >
    <div>
      <app-image-selector
        [elements]="vaporTypeValues"
        [control]="vaporControl"
      ></app-image-selector>
    </div>
  </div>
    <div class="col-12 section-box" >
      <div class="switch-icon-box">
        <div class="icon-box">
          <img 
            [ngClass]="{'selected': !switcher.useAlternateFan}"
            src="assets/images/fan-no.png" alt="Fan" (click)="setUseAlternateFan(false)" />
          </div>
          <div class="icon-box">
            <img 
            [ngClass]="{'selected': switcher.useAlternateFan}"
            src="assets/images/fan.png" alt="Alternate fan" (click)="setUseAlternateFan(true)" />
        </div>
      </div>
      <div>
        <app-dot-selector
          [elements]="fanSpedValues"
          [color]="'#f9b100'"
          [control]="fanControl"
        ></app-dot-selector>
      </div>
    </div>
  </div>
</ng-container>
<div class="modal-body p-3">
  <div class="row">
    <span>
      {{ 'DEVICE.ALARM.SUBTITLE'| translate: { days: 30 } }}
    </span>
    <div class="table-action-box">
      <button class="button-primary mx-2" (click)="getAlarms()">
        <i class="fa fa-sync-alt"></i> 
        {{ 'GLOBAL.UPDATE' | translate }}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <app-datatable
        [dataSource]="dataSource"
        [columns]="columns"
      ></app-datatable>
    </div>
  </div>
</div>

<ng-template #statusTpl let-row="row">
  <i class="fa-solid fa-circle" [ngStyle]="row.endTimestamp ? {'color': 'gray'} : {'color': 'red'}" ></i>
</ng-template>


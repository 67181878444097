import { Component, Input, OnInit } from '@angular/core';
import { DeviceBoDtoNBK } from 'src/app/api/nbk';

@Component({
  selector: 'app-online-device',
  templateUrl: './online-device.component.html',
  styleUrls: ['./online-device.component.scss']
})
export class OnlineDeviceComponent implements OnInit {
  @Input() device: DeviceBoDtoNBK;
  
  constructor( ) {  }

  ngOnInit(): void { }


}

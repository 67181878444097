import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnInit {
  @Input() controlValue: UntypedFormArray;
  @Input() isEditing: boolean;

  singleStep(step: AbstractControl) {
    return step as UntypedFormGroup;
  }

  constructor() {}

  ngOnInit(): void { }

  deleteStep(index: number) {
    this.controlValue.removeAt(index);
  }

  createNewStep() {
    (this.controlValue as UntypedFormArray).push(
      new UntypedFormGroup({
        id: new UntypedFormControl('',),
        name: new UntypedFormControl('', Validators.required),
        description: new UntypedFormControl(''),
        stepIngredient: new UntypedFormArray([]),
        images: new UntypedFormArray([])
      })
    );
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil, tap } from 'rxjs/operators';
import { DeviceBoDtoNBK, DeviceService, UserKeycloakDtoNBK, UserOvenInfoNBK, UserService } from 'src/app/api/nbk';
import { LocalizedDatePipe } from '../../pipes/localized-date.pipe';
import { getTimeZone, KEY_LANGUAGE_STORAGE } from 'src/app/core/utils';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-settings-device',
  templateUrl: './settings-device.component.html',
  styleUrls: ['./settings-device.component.scss']
})
export class SettingsDeviceComponent implements OnInit, OnDestroy {
  @Input() deviceSerial: string;

  lang: string;
  timezone: string;

  device: DeviceBoDtoNBK;
  ownerKcUser: UserOvenInfoNBK;

  unsubscribe$ = new Subject<void>();

  constructor(
    private ts: TranslateService,
    private datePipe: LocalizedDatePipe,
    private deviceService: DeviceService,
    private userService: UserService
  ) {

  }

  ngOnInit(): void {
    this.lang = localStorage.getItem(KEY_LANGUAGE_STORAGE)!;
    this.timezone = getTimeZone();
    
    this.deviceService.getDeviceBySerial(this.deviceSerial, localStorage.getItem(KEY_LANGUAGE_STORAGE)!)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((res) => { 
          this.device = res;
          if ( this.device.owner ) {
            this.userService.getUserOvenInfo(this.device.owner)      
              .pipe(
                takeUntil(this.unsubscribe$),
                tap((owner) => { 
                  this.ownerKcUser = owner;
                })
              ).subscribe()
          }
        })
      ).subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DisplayListBoDtoNBK } from 'src/app/api/nbk';
import { SelectableCookingMode } from 'src/app/core/utils';

@Component({
  selector: 'app-display-selection-modal',
  templateUrl: './display-selection-modal.component.html',
  styleUrls: ['./display-selection-modal.component.scss']
})
export class DisplaySelectionModal implements OnInit {
  @Input() displays: DisplayListBoDtoNBK[] = [];
  @Input() currentDisplayIds: number[] = [];
  @Input() cookingMode: SelectableCookingMode[];

  cookingModeDisplay: DisplayListBoDtoNBK[] = [];

  @Output() 
  displaySelected: EventEmitter<DisplayListBoDtoNBK> = new EventEmitter();

  selectDisplayFormGroup: UntypedFormGroup;

  constructor(public activeModal: NgbActiveModal) {
    this.selectDisplayFormGroup = new UntypedFormGroup({
      cookingMode: new UntypedFormControl(null),
      display: new UntypedFormControl(null,[Validators.required]),
    });
  }

  ngOnInit() {
    if (this.cookingMode) {
      this.selectDisplayFormGroup.get('cookingMode')?.setValidators([Validators.required]);
      this.selectDisplayFormGroup.get('display')?.disable();
    } else {
      this.cookingModeDisplay = this.displays;
    }
  }

  onCookingModeChange(event: SelectableCookingMode) {
    this.selectDisplayFormGroup.get('display')?.enable();
    this.selectDisplayFormGroup.get('display')?.setValue(undefined);
    this.cookingModeDisplay = this.displays.filter( d => d.cookingMode === event.id)
                              .map( e => {
                                return {
                                  disabled: this.currentDisplayIds.includes(e.id!),
                                  ...e
                                };
                              });
  }

  isCurrentDeviceDisplay(item: DisplayListBoDtoNBK) {
    return this.currentDisplayIds.includes(item.id!);
  }

  onConfirm() {
    if (this.selectDisplayFormGroup.invalid) {
      return;
    }

    const display = this.selectDisplayFormGroup.get('display')?.value;
    this.displaySelected.emit(display);

    this.activeModal.close();
  }

}

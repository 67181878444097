<!-- <label>{{ section }}</label> -->
<!-- <div class="input-group mb-3" *ngFor="let value of values; let i = index"> -->
<label class="custom-label mb-3">
  <input
    [formControl]="fieldControl"
    class="form-check-input"
    type="checkbox"
    #checkInput
    (change)="changeFilter()"
  />
  {{ label }}
</label>

<!-- </div> -->

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  NationDtoNBK,
  NationService,
  RecipeTypeBoDtoNBK,
  RecipeTypeService
} from 'src/app/api/nbk';
import { KEY_LANGUAGE_STORAGE } from 'src/app/core/utils';

@Injectable({
  providedIn: 'root'
})
export class RecipesListDataService {
  getCountryList$: Subject<NationDtoNBK[]> = new Subject();
  private countryList$: Subject<void> = new Subject();

  getRecipeTypeList$: Subject<RecipeTypeBoDtoNBK[]> = new Subject();
  private recipeTypeList$: Subject<void> = new Subject();

  constructor(
    private recipeTypeService: RecipeTypeService,
    private nationService: NationService
  ) {
    this.countryList$
      .pipe(
        switchMap(() =>
          this.nationService.getAllNations(
            localStorage.getItem(KEY_LANGUAGE_STORAGE)!,
            undefined,
            undefined,
            undefined,
            'currName asc'
          )
        ),
        map((res) => res.value),
        tap((res) => {
          this.getCountryList$.next(res!);
        })
      )
      .subscribe();

    this.recipeTypeList$
      .pipe(
        switchMap(() =>
          this.recipeTypeService.getAllRecipeTypes(
            localStorage.getItem(KEY_LANGUAGE_STORAGE)!
          )
        ),
        map((res) => res.value),
        tap((res) => {
          this.getRecipeTypeList$.next(res!);
        })
      )
      .subscribe();
  }

  getCountryList() {
    this.countryList$.next();
  }

  getRecipeTypeList() {
    this.recipeTypeList$.next();
  }
}

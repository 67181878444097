import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ODataFiltrableDataSource } from 'filtrable-data-source';
import { DeviceBoDtoNBK, DeviceService, PairTechniacianDevicesRequestNBK } from 'src/app/api/nbk';
import { Subject } from 'rxjs';
import { SelectedItemsHandler } from 'src/app/core/selected-items-handler';
import { KEY_LANGUAGE_STORAGE } from 'src/app/core/utils';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { dateRangeValidator } from 'src/app/core/date-utils';


@Component({
  selector: 'app-device-technician-selection-modal',
  templateUrl: './device-technician-association-modal.component.html',
  styleUrls: ['./device-technician-association-modal.component.scss']
})
export class DeviceTechnicianAssociationModal implements OnInit, OnDestroy {

  @Output() 
  saveAssociation: EventEmitter<PairTechniacianDevicesRequestNBK> = new EventEmitter();

  modalTitle: string;

  dataSource = new ODataFiltrableDataSource<DeviceBoDtoNBK>();
  columns: TableColumn[] = [];

  unsubscribe$ = new Subject<void>();

  dateRangeForm: UntypedFormGroup;
  selectionHandler = new SelectedItemsHandler<DeviceBoDtoNBK>( 
    (item: DeviceBoDtoNBK)  => false, 'id' );

  constructor(
    private ts: TranslateService, 
    public activeModal: NgbActiveModal,
    private deviceService: DeviceService ) { }

  ngOnInit() {
    this.columns = [
      {
        prop: 'serial',
        name: this.ts.instant('DEVICES.DEVICE_CARD.SERIAL_NUMBER')
      },
      {
        prop: 'model',
        name: this.ts.instant('DEVICES.DEVICE_CARD.MODEL')
      },
    ];

    this.dateRangeForm = new UntypedFormGroup({
      dateFrom: new UntypedFormControl(new Date(), {validators: [Validators.required]}),
      dateTo: new UntypedFormControl(new Date(Date.now() + 30 * 24 * 60 * 60 * 1000))
    }, { validators: this.dateRangeValidator });

    this.dataSource.fetchFunction = (top, skip, count, _orderBy, filter, search) => {
      return this.deviceService.getAllDevicesForServiceUser(
        localStorage.getItem(KEY_LANGUAGE_STORAGE)!,
        top,
        skip,
        count,
        _orderBy,
        filter, 
        search
      );
    };
    this.dataSource.limit = 7;    
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  dateRangeValidator (formGroup: AbstractControl) {
    return dateRangeValidator(formGroup);
  }


  onConfirm() {
    this.saveAssociation.emit( {
      userIds: [],
      deviceIds: this.selectionHandler.items.map( i => i.id! ),
      from: new Date( this.dateRangeForm.value.dateFrom).toISOString(),
      to:  this.dateRangeForm.value.dateTo ? new Date(this.dateRangeForm.value.dateTo).toISOString() : undefined
    });
    this.activeModal.close();
  }

}

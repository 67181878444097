import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { DeviceBoDtoNBK, UserKeycloakDtoNBK } from 'src/app/api/nbk';
import { AuthService } from 'src/app/core/auth.service';
import {
  getDeviceImage,
  InfoDeviceEnum,
  showDeviceInfo
} from 'src/app/core/device.utils';
import { getTimeZone, KEY_LANGUAGE_STORAGE } from 'src/app/core/utils';

@Component({
  selector: 'app-device-card',
  templateUrl: './device-card.component.html',
  styleUrls: ['./device-card.component.scss']
})
export class DeviceCardComponent implements OnInit, OnDestroy {
  @Input() device: DeviceBoDtoNBK;
  @Input() kcUser: UserKeycloakDtoNBK;
  @Output() update: EventEmitter<void> = new EventEmitter();

  unsubscribe$: Subject<void> = new Subject();

  lang: string;
  timezone: string;
  InfoDeviceEnum = InfoDeviceEnum;
  showedInHeader: InfoDeviceEnum;

  constructor(
    public authService: AuthService,
    private ts: TranslateService,
  ) {
    this.lang = localStorage.getItem(KEY_LANGUAGE_STORAGE)!;
    this.timezone = getTimeZone();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  checkIfViewInfo(key: InfoDeviceEnum) {
    return showDeviceInfo(key) && key !== this.showedInHeader;
  }

  loadDataSource() {
    this.update.emit();
  }

  getImage () {
    return getDeviceImage(this.device);
  }

  getHeaderTitle() {
    if ( showDeviceInfo(InfoDeviceEnum.name) ) {
      this.showedInHeader = InfoDeviceEnum.name;
      return this.device.name
    } else if ( showDeviceInfo(InfoDeviceEnum.serial) ) {
      this.showedInHeader = InfoDeviceEnum.serial;
      return this.device.serial;
    } else if ( showDeviceInfo(InfoDeviceEnum.hardware) ) {
      this.showedInHeader = InfoDeviceEnum.hardware;
      return this.device.hardwareId;
    } else {
      return '';
    }
  }
  
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl,  UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { getDeviceModelList, SelectableDeviceModel } from 'src/app/core/utils';
import { TranslateService } from '@ngx-translate/core';
import { DictionaryService } from 'src/app/core/dictionary.service';
import { contains, equal, ODataFiltrableDataSource } from 'filtrable-data-source';
import { DeviceDtoNBK } from 'src/app/api/nbk';
import { AuthService } from 'src/app/core/auth.service';
import { getDeviceTypeList, SelectableItem } from 'src/app/core/device.utils';

interface filterAction {
  [key: string]: () => void;
}

@Component({
  selector: 'app-device-filters',
  templateUrl: './device-filters.component.html',
  styleUrls: ['./device-filters.component.scss']
})
export class DeviceFiltersComponent implements OnInit, OnDestroy {
  forServiceUser: boolean;
  
  @Input()
  datasource: ODataFiltrableDataSource<DeviceDtoNBK>
  datasourceFilters: filterAction;

  deviceFilterForm: UntypedFormGroup;
  deviceModelList: SelectableDeviceModel[];
  deviceTypeList: SelectableItem[];

  unsubscribe$ = new Subject<void>();

  constructor(
    private ts: TranslateService,
    private ds: DictionaryService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.forServiceUser = this.authService.permissions.SERVICE

    this.datasourceFilters = {
      name: () => {
        this.datasource.setFilter(
          this.forServiceUser ? 'technicians/technicianDeviceName' : 'name', 
          contains(this.deviceFilterForm.value.name));
      },
      serial: () => {
        this.datasource.setFilter(
          'serial', 
          contains(this.deviceFilterForm.value.serial));
      },
      deviceModel: () => {
        this.datasource.setFilter(
          'model', 
          equal(this.deviceFilterForm.value.deviceModel));
      },
      deviceType: () => {
        this.datasource.setFilter(
          'type', 
          equal(this.deviceFilterForm.value.deviceType));
      },
      owner: () => {
        this.datasource.setSearch(this.deviceFilterForm.value.owner);
      },
      online: () => {
        this.datasource.setFilter(
          'online', 
          equal(true));
      }
    };

    this.deviceFilterForm = new UntypedFormGroup({
      name: new UntypedFormControl(''),
      serial: new UntypedFormControl(''),
      deviceModel: new UntypedFormControl(''),
      deviceType: new UntypedFormControl(''),
      owner: new UntypedFormControl(''),
      online: new UntypedFormControl(''),
    });

    this.deviceModelList = getDeviceModelList();
    this.search();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  search() {
    this.resetDatasourceFilter();
    Object.entries(this.deviceFilterForm.value).forEach(([key, value]) => {
      if ( value ) {
        this.datasourceFilters[key]();
      }
    });
    this.datasource.applyFilters();
  }

  clean() {
    this.deviceFilterForm.reset();
    this.search();
  }

  private resetDatasourceFilter() {
    this.datasource.removeFilter(this.forServiceUser ? 'technicians/technicianDeviceName' : 'name');
    this.datasource.removeFilter('serial');
    this.datasource.removeFilter('model');
    this.datasource.removeFilter('type');
    this.datasource.removeFilter('online');
    this.datasource.removeCustomFilter();
    this.datasource.removeSeach();
  }

  onDeviceModelChange($event: SelectableDeviceModel) {
    if ( $event.id === 'NABOO' ) {
      this.deviceTypeList = getDeviceTypeList($event.id);
      this.deviceFilterForm.controls['deviceType'].enable();
    } else {
      this.deviceTypeList = [];
      this.deviceFilterForm.controls['deviceType'].setValue(undefined);
      this.deviceFilterForm.controls['deviceType'].disable();
    }
  }

}

import {
  AfterContentInit, ChangeDetectorRef, Component, EventEmitter, OnInit,
  Output
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AirventDtoNBK, HoldingBoDtoNBK, PhaseActionBoDtoNBK, PhaseBoDtoNBK, PreheatDtoNBK, RecipeBoDtoNBK
} from 'src/app/api/nbk';
import { deviceConfiguration, toBooleanOptions, toSelectableItem } from 'src/app/core/utils';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { RecipeDetailService } from '../../recipe-detail.service';
import { pairwise } from 'rxjs/operators';
import { maintenanceConfiguration, PhaseBoDtoNBKComplete, PhaseCommonSetting, phaseCommonSettings, PhaseSingleSetting, phaseSingleSettings, preheatConfiguration } from 'src/app/core/cooking-step.utils';
import { EditedPhase } from '../cooking-steps.component';
import { PhaseSwitcher } from '../cooking-steps.handler';

interface ITabItem {
  ref: string;
  img: ITabImage;
  label: string;
  sectionList: ISectionList[];
}

interface ITabImage {
  url: string;
  alt: string;
}

export interface ISectionList {
  label: string;
  disabled: boolean | number[];
}

type ActionDto = PhaseActionBoDtoNBK & { name: string, checked: boolean };

@Component({
  selector: 'app-edit-cooking-steps-modal',
  templateUrl: './edit-cooking-steps-modal.component.html',
  styleUrls: ['./edit-cooking-steps-modal.component.scss']
})
export class EditCookingStepsModalComponent implements OnInit, AfterContentInit {

  @Output() cookingStepCreated = new EventEmitter<EditedPhase>();
  @Output() cookingStepsEdited = new EventEmitter<EditedPhase>();

  tabList: ITabItem[];
  phase?: PhaseBoDtoNBKComplete;
  firstPhase?: boolean;
  cookingStepFormGroup: UntypedFormGroup;
  cookingSteps: PhaseBoDtoNBK[];
  preheat?: PreheatDtoNBK;
  holding?: HoldingBoDtoNBK;
  airvent?: AirventDtoNBK;
  drying?: boolean;
  switcher: PhaseSwitcher;
  finishing?: RecipeBoDtoNBK.FinishingEnum; 
  previusPhaseType?: PhaseBoDtoNBK.CookingTypeEnum;
  selected: number = 0;
  tempUnit: 'C' | 'F';

  deviceModels = RecipeBoDtoNBK.DeviceModelEnum;

  holdingGroup = new UntypedFormGroup({
    type: new UntypedFormControl(''),
    temperature: new UntypedFormControl(''),
    humidity: new UntypedFormControl('')
  });
  get typeHolding() {
    return this.holdingGroup.get('type') as UntypedFormControl;
  }
  get temperatureHolding() {
    return this.holdingGroup.get('temperature') as UntypedFormControl;
  }

  preheatGroup = new UntypedFormGroup({
    type: new UntypedFormControl(''),
    temperature: new UntypedFormControl(''),
    humidity: new UntypedFormControl('')
  });
  get preheatType() {
    return this.preheatGroup.get('type') as UntypedFormControl;
  }
  get preheatTemperature() {
    return this.preheatGroup.get('temperature') as UntypedFormControl;
  }

  airventTypesList = toSelectableItem(AirventDtoNBK.TypeEnum,"PHASE.AIRVENT.");
  airventGroup = new UntypedFormGroup({
    type: new UntypedFormControl('CLOSE'),
    time: new UntypedFormControl(1),
  });
  get airventType() {
    return this.airventGroup.get('type') as UntypedFormControl;
  }
  get airventTime() {
    return this.airventGroup.get('time') as UntypedFormControl;
  }

  get softDryControl() {
    return this.cookingStepFormGroup.get('softDry') as UntypedFormControl;
  }

  get dryingControl() {
    return this.cookingStepFormGroup.get('drying') as UntypedFormControl;
  }

  finishingTypesList = toSelectableItem(RecipeBoDtoNBK.FinishingEnum);
  finishingForm = new UntypedFormControl('OFF');

  comandsControl = new UntypedFormArray([]);

  cookingMode: RecipeBoDtoNBK.CookingModeEnum;
  deviceModel: RecipeBoDtoNBK.DeviceModelEnum;
  cookingModeList = RecipeBoDtoNBK.CookingModeEnum;
  deviceModelList = RecipeBoDtoNBK.DeviceModelEnum;

  phaseType: string;
  preheatMinimuValue: number = 0;
  preheatConfiguration = preheatConfiguration;
  maintenanceConfiguration = maintenanceConfiguration;

  aromaSmokeValues = ['OFF', 'SOFT', 'MEDIUM', 'HARD'];
  onOffValues = toBooleanOptions("GLOBAL.");
  moisturizingValues = [0,1,2,3,4,5];

  maintementList = [
    {
      value: 0,
      name: 'Off'
    },
    {
      value: 1,
      name: 'Soft'
    },
    {
      value: 2,
      name: 'Medium'
    },
    {
      value: 3,
      name: 'Hard'
    }
  ];

  actionsList: ActionDto[];

  get customActionValue() {
    let customIndex = (this.comandsControl.value as any[]).findIndex((x) => x.type === 'CUSTOM');
    return customIndex >= 0 ? this.comandsControl.value[customIndex].customAction : undefined;
  }


  constructor(
    public activeModal: NgbActiveModal,
    public recipeDetailService: RecipeDetailService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.phaseType = this.setActiveTab();
    this.deviceModel = this.deviceModel || this.deviceModelList.Oracle;
    this.cookingMode = this.cookingMode || this.cookingModeList.Combi;
    this.tabList = this.setTabList();
    this.actionsList = this.generateActionsList();

    this.cookingStepFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(),
      phaseType: new UntypedFormControl(this.deviceModel),
      cookingType: new UntypedFormControl(this.phaseType),
      magnetron: new UntypedFormControl(0),
      fanSpeed: new UntypedFormControl(0),
      temperature: new UntypedFormControl(),
      time: new UntypedFormControl(0),
      cookingSettings: new UntypedFormGroup({
        timer: new UntypedFormControl(30),
        deltaTemperature: new UntypedFormControl(30),
        cameraTemperature: new UntypedFormControl(30),
        spilloneTemperature: new UntypedFormControl(10),
      }),
      fan: new UntypedFormGroup({
        type: new UntypedFormControl('NORMAL'),
        speed: new UntypedFormControl(1)
      }),
      drying: new UntypedFormControl(false), 
      softDry: new UntypedFormControl(false),
      vaporType: new UntypedFormControl('ECO'),
      moisturizing: new UntypedFormControl(0),
      autoclimate: new UntypedFormControl(0),
      smokegrill: new UntypedFormControl('OFF'),
      aroma: new UntypedFormControl('OFF'),
      actions: this.comandsControl,
    });
    
    this.recipeDetailService.initPreheatTemperature(
      this.cookingMode,
      this.firstPhase!,
      this.phase!,
      this.preheat!,
      this.tempUnit
    );

    if (this.phase) {
      this.populateActions(this.phase.actions || []);

      this.cookingStepFormGroup.patchValue(this.phase);
      this.phaseType = this.phase.cookingType || this.phaseType;
      this.cookingStepFormGroup.controls['cookingType'].setValue(
        this.phase.cookingType
      );
    }

    this.setRecipeFormElements();

    this.handlePreheatOnTemperatureChange();
  }

  private handlePreheatOnTemperatureChange() {
    this.cookingStepFormGroup
      .get('temperature')!
      .valueChanges.pipe(pairwise())
      .subscribe(([prev, next]) => {
        this.handlePreheat(prev !== next, next);
      });

    (this.cookingStepFormGroup
      .controls['cookingSettings'] as UntypedFormGroup)
      .controls['cameraTemperature']
      .valueChanges.pipe(pairwise())
      .subscribe(([prev, next]) => {
        this.handlePreheat(prev !== next, next);
      });

    (this.cookingStepFormGroup
      .controls['cookingSettings'] as UntypedFormGroup)
      .controls['deltaTemperature']
      .valueChanges.pipe(pairwise())
      .subscribe(([prev, next]) => {
        const temp = next + (this.cookingStepFormGroup
          .controls['cookingSettings'] as UntypedFormGroup)
          .controls['spilloneTemperature'].value;
        this.handlePreheat(prev !== next, next);
      });
    
    (this.cookingStepFormGroup
      .controls['cookingSettings'] as UntypedFormGroup)
      .controls['spilloneTemperature']
      .valueChanges.pipe(pairwise())
      .subscribe(([prev, next]) => {
        const temp = next + (this.cookingStepFormGroup
          .controls['cookingSettings'] as UntypedFormGroup)
          .controls['deltaTemperature'].value;
        this.handlePreheat(prev !== next, next);
      });
  }

  private handlePreheat(valueChange: boolean, temp: any) {
    if (this.firstPhase && valueChange) {
      this.recipeDetailService.updatePreheatTemperature(
        this.cookingMode,
        temp || 0,
        this.tempUnit
      );
      this.preheatMinimuValue =
        this.recipeDetailService.getPreheatMinimumValue();

      setTimeout(() => {
        this.preheat!.temperature =
          this.recipeDetailService.getPreheatInitialTemperature();
        this.preheatGroup.patchValue(this.preheat!);
      }, 0);
    }
  }

  private setActiveTab() {
    switch (this.cookingMode) {
      case 'COMBI':
      case 'NABOO':
        return 'CONVECTION';
      case 'NEO':
        if ( this.phase ) {
          return this.phase.cookingType!;
        } else if ( this.previusPhaseType !== undefined ) {
          return this.previusPhaseType;
        }
        return 'BLAST_CHILLING';
      default:
        return 'MICROWAVE';
    }
  }

  private setTabList() {
    const device = deviceConfiguration[this.deviceModel];
    let tabs = device.find((mode) => mode.coockingMode === this.cookingMode)?.tabList || [];
    if ( this.deviceModel === 'NEO' ) {
      tabs = tabs.filter( e => { 
        if ( this.phase ) {
          if ( this.phase.cookingType === 'LEAVENING' ) {
            return e.ref === 'LEAVENING';
          } else if ( !this.firstPhase || this.cookingSteps.length > 1 ) {
            return e.ref === this.phase.cookingType;
          }
        } else if ( this.previusPhaseType !== undefined ) {
          return e.ref === this.previusPhaseType
        }

        return e.ref !== 'LEAVENING';
      });
    }
    return tabs;
  }

  private setRecipeFormElements() {
    if (this.holding) {
      this.holdingGroup.patchValue(this.holding);
    }

    if (this.airvent) {
      this.airventGroup.patchValue(this.airvent);
    }

    if (this.preheat) {
      this.preheat.temperature = this.recipeDetailService.getPreheatInitialTemperature();
      this.preheatMinimuValue = this.recipeDetailService.getPreheatMinimumValue();
      this.preheatGroup.patchValue(this.preheat);
    }

    if (this.finishing) {
      this.finishingForm.patchValue(this.finishing);
    }

    if ( this.drying ) {
      this.dryingControl.patchValue(this.drying);
    }

  }

  ngAfterContentInit() {
    this.changeDetector.detectChanges();
  }

  navChange(v: any) {
    this.phaseType = v.nextId;
    this.cookingStepFormGroup.controls['cookingType'].setValue(v.nextId);
  }

  saveCookingStep() {
    if (this.phase) {
      this.cookingStepsEdited.next({
        index: this.selected,
        formData: this.cookingStepFormGroup,
        holdingData: this.holdingGroup,
        preheatData: this.preheatGroup,
        airventGroup: this.airventGroup,
        finishingData: this.finishingForm,
        dryingData: this.dryingControl,
        switcher: this.switcher
      });
    } else {
      this.cookingStepCreated.next({
        formData: this.cookingStepFormGroup,
        holdingData: this.holdingGroup,
        preheatData: this.preheatGroup,
        airventGroup: this.airventGroup,
        finishingData: this.finishingForm,
        dryingData: this.dryingControl,
        switcher: this.switcher
      });
    }
  }

  setAction(a: any, type: string) {
    let currentFormValue: any[] = this.comandsControl.value;
    this.actionsList.forEach( e => e.checked = false );
    this.comandsControl.clear();
    
    if (a.currentTarget.checked) {
      if ( type !== 'CUSTOM' ) {
        this.removeCommand(currentFormValue,'CUSTOM');
      }

      this.comandsControl.push(
        new UntypedFormGroup({
          type: new UntypedFormControl(type),
          customAction: new UntypedFormControl(undefined)
        })
      );
      this.actionsList.filter( a => a.type === type ).forEach( a => a.checked = true );
    } else if ( currentFormValue.length > 1 ) {
      this.removeCommand(currentFormValue,type);
    }
  }

  private removeCommand (currentFormValue: any[], type: string) {
    let customIndex = currentFormValue.findIndex((x) => x.type === type);
    if (customIndex > -1) {
      currentFormValue.splice(customIndex, 1);
    }

    for (let e of currentFormValue) {
      this.actionsList.filter( a => a.type === e.type ).forEach( a => a.checked = true );
      this.comandsControl.push(
        new UntypedFormGroup({
          type: new UntypedFormControl(e.type),
          customAction: new UntypedFormControl(e.customAction)
        })
      );
    }
  }

  setCustomActionValue(event: any) {
    let customIndex = (this.comandsControl.value as any[]).findIndex((x) => x.type === 'CUSTOM');
    this.comandsControl.get(`${customIndex}`)?.get('customAction')?.patchValue(event.currentTarget.value);
  }

  selectStep(stepIndex: number) {
    this.selected = stepIndex;
    this.cookingStepFormGroup.patchValue(this.cookingSteps[stepIndex]);
    this.populateActions(this.cookingSteps[stepIndex].actions || []);
    this.phaseType = this.cookingStepFormGroup.controls['cookingType'].value;
  }

  populateActions(actions: PhaseActionBoDtoNBK[]): void {
    const newActionList = this.generateActionsList();

    this.actionsList = [];

    this.comandsControl.controls = [];
    actions?.forEach((action) => {
      const index = newActionList.findIndex((a) => a.type === action.type);

      if (index !== -1) {
        newActionList[index].checked = true;
        this.comandsControl.push(
          new UntypedFormGroup({
            customAction: new UntypedFormControl(action.customAction),
            type: new UntypedFormControl(action.type)
          })
        );
      }
    });

    this.actionsList = newActionList.map((action) => action);
  }

  generateActionsList() {
    const newActionList = [] as ActionDto[];

    Object.keys(PhaseActionBoDtoNBK.TypeEnum).forEach( e => {
      const type = e.toUpperCase();
      newActionList.push({
        type: type as PhaseActionBoDtoNBK.TypeEnum,
        name: `RECIPE.PHASE.ACTION.${type}`,
        checked: false
      })
    });

    return newActionList;
  }

  showCustomCommandTextArea() {
    return this.comandsControl.value.map( (e: any) => e.type).includes('CUSTOM');
  }

  onInitPreheat() {
    this.recipeDetailService.initPreheatTemperature(
      this.cookingMode,
      this.firstPhase!,
      this.phase!,
      this.preheat!,
      this.tempUnit
    );
    this.preheat!.temperature =
      this.recipeDetailService.getPreheatInitialTemperature();
    this.preheatMinimuValue = this.recipeDetailService.getPreheatMinimumValue();
  }

  showSinglePhaseSetupSection() {
    return phaseSingleSettings(this.cookingMode).length > 0;
  }

  showCommonPhaseSetupItem(control: PhaseCommonSetting) {
    const commons = phaseCommonSettings(this.cookingMode);
    return commons.includes(control);
  }

  showSinglePhaseSetupItem(control: PhaseSingleSetting) {
    const singles = phaseSingleSettings(this.cookingMode);
    return singles.includes(control);
  }


}

import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-parameter-device',
  templateUrl: './modal-device-parameter.component.html',
  styleUrls: ['./modal-device-parameter.component.scss']
})
export class ModalDeviceParameter implements OnInit {
  deviceId: string;

  constructor(
    public activeModal: NgbActiveModal,
  ) {  }

  ngOnInit(): void { }

}

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import {
  IngredientBoDtoNBK,
  StepBoDtoNBK,
  StepIngredientDtoNBK
} from 'src/app/api/nbk';
import {
  IngredientBoDtoNBKComplete,
  IngredientsService
} from './ingredients.service';

@Component({
  selector: 'app-ingredients',
  templateUrl: './ingredients.component.html',
  styleUrls: ['./ingredients.component.scss']
})
export class IngredientsComponent implements OnInit {
  @Input() steps: StepBoDtoNBK[];
  @Input() isEditing: boolean;
  ingredients: IngredientBoDtoNBK[] = [];
  dataSource = new ClientFiltrableDataSource<IngredientBoDtoNBKComplete>();
  columns: TableColumn[] = [];

  @ViewChild('allergensTpl', { static: true })
  allergensTpl: ElementRef<HTMLElement>;

  constructor(
    private ts: TranslateService,
    private ingredientsService: IngredientsService
  ) {}

  ngOnInit(): void {
    this.ingredientsService.ingredients$.subscribe(
      (ingr: IngredientBoDtoNBK[]) => {
        this.ingredients = ingr;
      }
    );
    let stepsIngr: StepIngredientDtoNBK[] = [];
    this.steps.forEach((element: StepBoDtoNBK) => {
      stepsIngr = stepsIngr.concat(element.stepIngredient || []);
    });

    this.dataSource.setItems(
      this.ingredientsService.getFullIngredients(stepsIngr)
    );

    this.columns = [
      {
        prop: 'name',
        name: this.ts.instant('RECIPE.INGREDIENT')
      },
      {
        prop: 'quantity',
        name: this.ts.instant('RECIPE.QUANTITY')
      },
      {
        prop: 'um',
        name: this.ts.instant('RECIPE.UM')
      },
      {
        prop: 'cal',
        name: this.ts.instant('RECIPE.CALORIES')
      },
      /* {
        prop: 'foodCost',
        name: this.ts.instant('RECIPE.FOOD_COST')
      }, */
      {
        prop: 'allergens',
        name: this.ts.instant('RECIPE.ALLERGENS'),
        cellTemplate: this.allergensTpl
      },
      {
        prop: 'notes',
        name: this.ts.instant('RECIPE.NOTES')
      }
    ];
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { DeviceService, DeviceSettingsDtoNBK, DeviceSettingsNBK } from 'src/app/api/nbk';
import { LocalizedDatePipe } from '../../pipes/localized-date.pipe';
import { checkFormValidity, getTimeZone, KEY_LANGUAGE_STORAGE } from 'src/app/core/utils';
import { of, Subject } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { getEnableDisableList, getProfileLockList, getVoltageTypeList, SelectableItem } from 'src/app/core/device.utils';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/auth.service';
import { canServiceUserEdit } from 'src/app/core/permission.utils';

@Component({
  selector: 'app-user-settings-device',
  templateUrl: './user-settings-device.component.html',
  styleUrls: ['./user-settings-device.component.scss']
})
export class UserSettingsDeviceComponent implements OnInit, OnDestroy {
  @Input() deviceId: string;

  lang: string;
  timezone: string;
  isEditing: boolean = false;
  isLoading: boolean = true;

  deviceSettings: DeviceSettingsDtoNBK;
  settings: any;
  deviceSettingsFormGroup: UntypedFormGroup;

  profileLockList: SelectableItem[];
  voltageTypeList: SelectableItem[];
  enableDisableList: SelectableItem[];

  unsubscribe$ = new Subject<void>();

  constructor(
    private ts: TranslateService,
    private datePipe: LocalizedDatePipe,
    private toastrService: ToastrService,
    private deviceService: DeviceService,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.lang = localStorage.getItem(KEY_LANGUAGE_STORAGE)!;
    this.timezone = getTimeZone();

    this.generateSettingsForm();
    this.generateEnumLists();
    
    this.getSettings();
    
  }


  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private generateSettingsForm() {
    this.deviceSettingsFormGroup = new UntypedFormGroup({
      deviceId: new UntypedFormControl(this.deviceId),
      BloccoProfilo: new UntypedFormControl(undefined, [Validators.required]),
      PasswordBlocco: new UntypedFormControl(undefined,[Validators.min(4)]),
      BloccoProfiloTimeout: new UntypedFormControl(undefined, [Validators.required]),
      TempPreriscaldoHso: new UntypedFormControl(undefined, [Validators.required, Validators.min(100), Validators.max(250)]),
      PasswordPreriscaldoHso: new UntypedFormControl(undefined,[Validators.min(4)]),
      Brightness: new UntypedFormControl(undefined, [Validators.required, Validators.min(10), Validators.max(100)]),
      ScreenSaverTime: new UntypedFormControl(undefined, [Validators.required]),
      SoundEnabled: new UntypedFormControl(undefined, [Validators.required]),
      VolumeAudio: new UntypedFormControl(undefined, [Validators.required, Validators.min(0), Validators.max(100)]),
      IdxSoundAlarm: new UntypedFormControl(undefined, [Validators.required, Validators.min(1), Validators.max(11)]),
      IdxSoundCookingEnd: new UntypedFormControl(undefined, [Validators.required, Validators.min(1), Validators.max(11)]),
      IdxSoundAlert: new UntypedFormControl(undefined, [Validators.required, Validators.min(1), Validators.max(11)]),
      CookingStandbySound: new UntypedFormControl(undefined, [Validators.required]),
      HomeExtendedView: new UntypedFormControl(undefined, [Validators.required]),
      BackupAlertDoNotShow: new UntypedFormControl(undefined, [Validators.required]),
      CookPauseWithUsrCmd: new UntypedFormControl(undefined, [Validators.required]),
      ShortcutsEnabled: new UntypedFormControl(undefined, [Validators.required]),
      ChefSupport: new UntypedFormControl(undefined, [Validators.min(4)]),
      TechnicalSupport: new UntypedFormControl(undefined, [Validators.min(4)]),
      VoltageType: new UntypedFormControl(undefined, [Validators.required])
    });
  }

  private getDefaultObeject() {
    return {
      deviceId: +this.deviceId,
      BloccoProfilo: 0,
      PasswordBlocco: '',
      BloccoProfiloTimeout: 0,
      TempPreriscaldoHso: 150,
      PasswordPreriscaldoHso: '',
      Brightness: 50,
      ScreenSaverTime: 1,
      SoundEnabled: true,
      VolumeAudio: 50,
      IdxSoundAlarm: 1,
      IdxSoundCookingEnd: 1,
      IdxSoundAlert: 1,
      CookingStandbySound: true,
      HomeExtendedView: true,
      BackupAlertDoNotShow: false,
      CookPauseWithUsrCmd: true,
      ShortcutsEnabled: false,
      ChefSupport: '',
      TechnicalSupport: '',
      VoltageType: 1
    };
  }

  private populateForm(settings: DeviceSettingsNBK[]) {
    settings.forEach(setting => {
      const formControl = this.deviceSettingsFormGroup.get(setting.id!);
      
      if (formControl) {
        let convertedValue: any = setting.value;
  
        switch (setting.id!) {
          case 'BloccoProfilo':
          case 'BloccoProfiloTimeout':
          case 'TempPreriscaldoHso':
          case 'Brightness':
          case 'ScreenSaverTime':
          case 'VolumeAudio':
          case 'IdxSoundAlarm':
          case 'IdxSoundCookingEnd':
          case 'IdxSoundAlert':
          case 'VoltageType':
            convertedValue = parseInt(setting.value!);
            break;
          
          case 'SoundEnabled':
          case 'CookingStandbySound':
          case 'HomeExtendedView':
          case 'BackupAlertDoNotShow':
          case 'CookPauseWithUsrCmd':
          case 'ShortcutsEnabled':
            convertedValue = setting.value === '1';
            break;
          
          default:
            convertedValue = setting.value;
            break;
        }

        formControl.setValue(convertedValue);
      }
    });
  }

  private toToDeviceSettings(): DeviceSettingsNBK[] {
    const settings: DeviceSettingsNBK[] = [];
  
    Object.keys(this.deviceSettingsFormGroup.controls).forEach(key => {
      const formControl = this.deviceSettingsFormGroup.get(key);
      let value = formControl?.value;
  
      // Conversione a stringa in base al tipo di valore
      switch (key) {
        case 'BloccoProfilo':
        case 'BloccoProfiloTimeout':
        case 'TempPreriscaldoHso':
        case 'Brightness':
        case 'ScreenSaverTime':
        case 'VolumeAudio':
        case 'IdxSoundAlarm':
        case 'IdxSoundCookingEnd':
        case 'IdxSoundAlert':
        case 'VoltageType':
          value = value.toString();
          break;
        
        case 'SoundEnabled':
        case 'CookingStandbySound':
        case 'HomeExtendedView':
        case 'BackupAlertDoNotShow':
        case 'CookPauseWithUsrCmd':
        case 'ShortcutsEnabled':
          value = value ? '1' : '0';
          break;
  
        default:
          // Se non richiede conversione, trasformiamo comunque in stringa
          value = value
          break;
      }
  
      // Crea un nuovo DeviceSettings con il nome del campo e il valore convertito
      settings.push({ id: key, value });
    });
  
    return settings;
  }
  
  private generateEnumLists() {
    this.profileLockList = getProfileLockList();
    this.voltageTypeList = getVoltageTypeList();
    this.enableDisableList = getEnableDisableList();
  }

  toggleEdit(restoreData: boolean) {
    this.isEditing = !this.isEditing;
    if ( restoreData ) {
      this.deviceSettingsFormGroup.patchValue(this.settings);
    }
  }

  canEdit () {
    return canServiceUserEdit(this.authService);
  }

  getSettings() {
    this.isLoading = true;
    this.deviceService.getDeviceSettings(+this.deviceId)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((res) => {
          this.deviceSettings = res;
          this.populateForm(res.settings!);
          this.settings = this.deviceSettingsFormGroup.value;
        }),
        catchError((error) => {
          this.deviceSettings = {
            deviceId: +this.deviceId
          };
          this.settings = this.canEdit() ? this.getDefaultObeject() : {};
          this.deviceSettingsFormGroup.patchValue(this.settings);
          return of(null);
        }), 
        finalize(() => this.isLoading = false)
      ).subscribe();
  }

  saveSetting () {
    if (checkFormValidity(this.deviceSettingsFormGroup)) {
      this.deviceSettings.settings = this.toToDeviceSettings();

      this.deviceService.setDeviceSettings(+this.deviceId, this.deviceSettings)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => { 
          this.toastrService.success(
            this.ts.instant('DEVICE.SETTINGS.SUCCESS_MESSAGE'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.toggleEdit(false);
        })
      ).subscribe();
    }

  }

}

import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-dot-selector',
  templateUrl: './dot-selector.component.html',
  styleUrls: ['./dot-selector.component.scss']
})
export class DotSelectorComponent {

  @Input() elements: any[] = [];
  @Input() color: string = '#555';
  @Input() firstAsOff: boolean = false;
  @Input() control: AbstractControl<any>

  pxDotBaseSize: number = 11; 
  dotSizeScale: number = 1.2;


  setControlValue(index: number) { 
    if ( !this.isDisabled() ) {
      this.control.setValue(this.elements[index]);
    }
  }
  
  calculateDotSize(index: number): string {
    return `${this.pxDotBaseSize * Math.pow(this.dotSizeScale, index)}px`;
  }

  getDotColor(index: number): string {
    if ( this.firstAsOff && index === 0 ) {
      return this.elements.lastIndexOf(this.control.value) > 0 ? '#ccc' : 'red';
    }
    return index <= this.elements.lastIndexOf(this.control.value) ? this.color : '#ccc';
  }

  isDisabled() {
    return this.control.disabled;
  }

}

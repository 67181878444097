<div id="neoHandler">
  <div class="row neo-phase-selector" *ngIf="showNeoPhaseSelector">
    <div class="col-12 col-lg-4">
      <button class="button-primary" (click)="hideNeoPhaseSelector()">
        <i class="fa fa-snowflake"></i> {{ 'PHASE.NEO.ADD_CHILLING' | translate }}
      </button>
    </div>
    <div class="col-12 col-lg-4">
      <button class="button-primary" (click)="createLeaveningPhases()">
        <i class="fa fa-bread-slice"></i> {{ 'PHASE.NEO.ADD_LEAVENING' | translate }}
      </button>
    </div>
  </div>
  <div class="row my-3" *ngIf="isEditing && cookingMode === 'NEO' && !multilevel && !showNeoPhaseSelector">
    <div class="col-12 neo-phase-cleaner">
      <button class="button-danger" (click)="resetNeoPhases()">
        <i class="fa fa-trash"></i> {{ 'PHASE.NEO.RESET' | translate }}
      </button>
    </div>
  </div>
</div>

<div id="cookig-steps" *ngIf="!showNeoPhaseSelector">
  <div *ngIf="preheatValue">
    <p>
      {{ 'COOKINGSTEP.PREHEAT' | translate }}:
      {{ preheatValue | temperatureUnit: tempUnit }}
    </p>
  </div>
  
  <ngx-datatable
    class="material fixed-tooltip"
    [columns]="columns"
    [rows]="cookingSteps.currentItems"
    [limit]="cookingSteps.limit"
    [offset]="cookingSteps.page"
    [count]="cookingSteps.totalItems || 0"
    [externalPaging]="true"
    [externalSorting]="true"
    [scrollbarH]="true"
    columnMode="force"
    (page)="cookingSteps.onTablePage($event)"
    (sort)="cookingSteps.onTableSort($event)"
    [selectAllRowsOnPage]="false"
  >
  </ngx-datatable>
  
  <div *ngIf="holdingType !== 'Off'">
    <p>
      {{ 'COOKINGSTEP.HOLDING' | translate }}: {{ holdingType }} /
      {{ 'COOKINGSTEP.SET' | translate }}:
      {{ holdingValue | temperatureUnit: tempUnit }}
    </p>
  </div>
  
  <button
    *ngIf="canCreate()"
    id="create-button"
    class="button-primary mx-auto d-block mt-3"
    (click)="createNewStep()"
  >
    <i class="fa fa-plus"></i> {{ 'RECIPE.ADD_COOOKING_CYCLES' | translate }}
  </button>
</div>


<ng-template #editTpl let-row="row" let-rowIndex="rowIndex">
  <i 
    *ngIf="isEditing"
    class="table-icons fa fa-fw fa-pen"
    (click)="editCookingStep(row, rowIndex)" 
  ></i>
  <i 
    *ngIf="canDelete(row)"
    class="table-icons fa fa-fw fa-times"
    (click)="removeCookingStep(rowIndex)"
  ></i>
</ng-template>

<ng-template #idTpl let-row="row" let-rowIndex="rowIndex" let-value="value">
  {{rowIndex + 1}}
</ng-template>

<ng-template #actionTpl let-row="row">
  <span class="action-tpl" #el [tooltip]="setActionValue(row.actions)" placement="bottom"
    [isDisabled]="disableTooltip(el)">
    {{ setActionValue(row.actions) }}
  </span>
</ng-template>

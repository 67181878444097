import { Injectable } from '@angular/core';
import { KeycloakOptions, KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { UserKeycloakDtoNBK } from '../api/nbk';

@Injectable({
  providedIn: 'root'
})
export class MykeycloakService {
  user: KeycloakProfile = {};
  roles: UserKeycloakDtoNBK.RolesEnum[] = [];

  constructor(private keycloakService: KeycloakService) {}

  init(options: KeycloakOptions): Promise<void> {
    return this.keycloakService.init(options).then(() => {
      this.keycloakService.loadUserProfile().then((data) => {
        this.refreshUserData(data);
        return true;
      });
    });
  }

  refreshUserData(data: KeycloakProfile) {
    this.user = data;
    this.keycloakService.getUserRoles(true).forEach((role) => {
      this.roles.push(role.toUpperCase() as UserKeycloakDtoNBK.RolesEnum);
    });
  }

  getUser(): KeycloakProfile {
    return this.user;
  }

  getToken() {
    return this.keycloakService.getToken();
  }

  logout() {
    return this.keycloakService.logout(window.location.origin);
  }

}

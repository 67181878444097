import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import {
  equal,
  filterOperatorType,
  ODataFiltrableDataSource
} from 'filtrable-data-source';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FiltersStorageService } from '../filters-storage.service';
import { FiltersService } from '../filters.service';

@Component({
  selector: 'app-data-source-checkbox-field',
  templateUrl: './data-source-checkbox-field.component.html',
  styleUrls: ['./data-source-checkbox-field.component.scss']
})
export class DataSourceCheckboxFieldComponent implements OnInit, OnDestroy {
  @Input() dataSource: ODataFiltrableDataSource<unknown>;
  @Input() field: string;
  @Input() fieldControl: UntypedFormControl = new UntypedFormControl(undefined);
  @Input() label: string;
  @Input() operator: filterOperatorType;
  @Input() value: number | boolean | string;
  @Input() applyOnChanges: boolean = true;
  private unsuscribe$: Subject<void> = new Subject();

  @ViewChild('checkInput') checkInput: ElementRef<HTMLInputElement>;

  constructor(
    private router: Router,
    private filtersStorageService: FiltersStorageService,
    private filtersService: FiltersService
  ) {}

  ngOnInit(): void {
    this.filtersService.filtersCleared
      .pipe(takeUntil(this.unsuscribe$))
      .subscribe(() => {
        this.initFilterValue();
      });

    this.initFilterValue();
  }

  changeFilter(): void {
    if (this.fieldControl.value) {
      if (this.operator === 'equal') {
        this.dataSource.setFilter(this.field, equal(this.value));
      }
      if (this.applyOnChanges) {
        this.dataSource.applyFilters();
      }
    } else {
      this.dataSource.removeFilter(this.field);
      if (this.applyOnChanges) {
        this.dataSource.applyFilters();
      }
      this.filtersStorageService.removeItem(this.router.url, this.field);
      this.filtersService.removeFilters();
    }
  }

  ngOnDestroy(): void {
    this.unsuscribe$.next();
    this.unsuscribe$.complete();
  }

  private initFilterValue(): void {
    //this.fieldArray.controls = this.values.map(() => new FormControl(false));
    //this.operator = 'in';
  }
}

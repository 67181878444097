import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { umList } from 'src/app/core/utils';
import { CreateIngredientModalComponent } from 'src/app/shared/create-ingredient-modal/create-ingredient-modal.component';
import { IngredientBoDtoNBKComplete, IngredientsService } from '../../../ingredients/ingredients.service';
import { canHandleIcsItem } from 'src/app/core/permission.utils';
import { AuthService } from 'src/app/core/auth.service';

@Component({
  selector: 'app-edit-step-ingredient-modal',
  templateUrl: './edit-step-ingredient-modal.component.html',
  styleUrls: ['./edit-step-ingredient-modal.component.scss']
})
export class EditStepIngredientModalComponent implements OnInit {
  ingredient: IngredientBoDtoNBKComplete;
  ingredientFormGroup: UntypedFormGroup;

  umList = umList;
  ingredientEdited = new EventEmitter<UntypedFormGroup>();
  constructor(
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    public authService: AuthService,
    private ingredientService: IngredientsService
  ) {}

  ngOnInit(): void {
    this.ingredientFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(''),
      name: new UntypedFormControl(''),
      um: new UntypedFormControl(''),
      calories: new UntypedFormControl(''),
      allergens: new UntypedFormArray([]),
      density: new UntypedFormControl(''),
      quantity: new UntypedFormControl('')
    });

    this.ingredientFormGroup.patchValue(this.ingredient);
  }

  editStartingIngredient() {
    const modalRef = this.modalService.open(CreateIngredientModalComponent, {
      backdrop: 'static',
      modalDialogClass: 'modal-md'
    });
    modalRef.componentInstance.ingredient = this.ingredient;
    modalRef.componentInstance.ingredientEdited.subscribe((v: UntypedFormGroup) => {
      this.ingredientService.getIngredients();
      this.ingredientFormGroup.patchValue(v.value);
    });
  }

  editIngredient() {
    if (this.ingredientFormGroup.valid) {
      this.ingredientEdited.next(this.ingredientFormGroup);
    }
  }

  canHandleItem() {
    return canHandleIcsItem(this.ingredient, this.authService);
  }

}

<div class="modal-header">
  <h2>{{ 'ACCESSORY.TITLE' | translate }}</h2>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-3 d-flex flex-column" [formGroup]="accessoryForm">
  <label class="custom-label mb-3"
    >{{ 'ACCESSORY.NAME' | translate }}
    <input
      type="text"
      class="custom-input w-100"
      [placeholder]="'ACCESSORY.NAME' | translate"
      formControlName="name"
    />
  </label>
  <label class="custom-label mb-3"
    >{{ 'ACCESSORY.DESCRIPTION' | translate }}
    <input
      type="text"
      class="custom-input w-100"
      [placeholder]="'ACCESSORY.DESCRIPTION' | translate"
      formControlName="description"
    />
  </label>
  <label class="mt-2 custom-label">{{ 'ACCESSORY.IMAGE' | translate }}</label>
  <app-upload-image
    (imageChangedBlob)="imageChanged($event)"
    [imageUrl]="imageUrl"
    [isEditing]="isCreate || isEdit"
    otherId="accessory-image"
  ></app-upload-image>
</div>
<div class="modal-footer">
  <button
    class="button-primary"
    (click)="create()"
    [disabled]="!accessoryForm.valid"
    *ngIf="isCreate"
  >
    {{ 'GLOBAL.CREATE' | translate }}
  </button>

  <button
    class="button-primary"
    (click)="update()"
    [disabled]="!accessoryForm.valid"
    *ngIf="isEdit"
  >
    {{ 'GLOBAL.UPDATE' | translate }}
  </button>
</div>

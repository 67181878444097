import { Injectable } from '@angular/core';
import { filterOperatorType } from 'filtrable-data-source';
import { MykeycloakService } from '../../../core/mykeycloak.service';
import { basePathGetter } from '../base-path-getter';

const LOCAL_STORAGE_FILTERS_KEY_PREFIX = 'TableFilters_';

export interface FilterStorageState {
  value: string;
  operator: filterOperatorType;
}

@Injectable({
  providedIn: 'root'
})
export class FiltersStorageService {
  constructor(private kc: MykeycloakService) {}

  setItem(url: string, field: string, filterState: FilterStorageState) {
    const localStorageKey = this.getStorageKey(url, field);
    const operatorLocalStorageKey = this.getStorageKey(
      url,
      `operator_${field}`
    );
    localStorage.setItem(localStorageKey, filterState.value);
    localStorage.setItem(operatorLocalStorageKey, filterState.operator);
  }

  getItem(url: string, field: string): FilterStorageState {
    const localStorageKey = this.getStorageKey(url, field);
    const localOperatorStorageKey = this.getStorageKey(
      url,
      `operator_${field}`
    );
    return this.getStorageValue(localStorageKey, localOperatorStorageKey);
  }

  removeItem(url: string, field: string) {
    const localStorageKey = this.getStorageKey(url, field);
    const localOperatorStorageKey = this.getStorageKey(
      url,
      `operator_${field}`
    );
    localStorage.removeItem(localStorageKey);
    localStorage.removeItem(localOperatorStorageKey);
  }

  private getStorageKey(url: string, field: string): string {
    return `${
      this.kc.user.username
    }_${LOCAL_STORAGE_FILTERS_KEY_PREFIX}${basePathGetter(url)}$${field}`;
  }

  private getStorageValue(
    localStorageKey: string,
    operatorLocalStorageKey: string
  ): FilterStorageState {
    const value = localStorage.getItem(localStorageKey)!;
    const operator = localStorage.getItem(
      operatorLocalStorageKey
    ) as filterOperatorType;
    const filterState = {
      operator,
      value
    };

    return filterState;
  }
}

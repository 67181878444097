<div class="modal-body p-3">
  <div class="row">
    <span *ngIf="deviceSettings">
      {{ 'GLOBAL.LAST_UPDATE'| translate: { time: (deviceSettings.lastUpdate 
        ? (deviceSettings.lastUpdate | fromUTCToLocale | date: 'medium':timezone:lang)
        : '') } }}
    </span>
    <div class="table-action-box">
      <button 
        *ngIf="!isEditing"
        class="button-primary" 
        [disabled]="isLoading"
        (click)="getSettings()"
      >
        <i class="fa fa-sync-alt"></i> 
        {{ 'GLOBAL.UPDATE' | translate }}
      </button>
      <button 
        *ngIf="canEdit() && !isEditing"
        [disabled]="isLoading" 
        class="button-primary" 
        (click)="toggleEdit(false)"
      >
        <i class="fa fa-pen"></i> {{ 'GLOBAL.EDIT' | translate }}
      </button>
      <button 
        *ngIf="isEditing"
        class="button-primary" 
        (click)="toggleEdit(true)"
      >
        <i class="fa fa-times"></i> {{ 'GLOBAL.UNDO' | translate }}
      </button>
      <button 
        *ngIf="isEditing"
        class="button-primary" 
        (click)="saveSetting()"
      >
        <i class="fa fa-save"></i> {{ 'GLOBAL.SAVE' | translate }}
      </button>
    </div>
  </div>
  
  <div class="settings-body row" [formGroup]="deviceSettingsFormGroup">
  
      <div class="col-12 col-lg-5 box">
        <div class="title">
          <label>{{ 'DEVICE.SETTINGS.PREFENCE.TITLE' | translate }}</label>
        </div>
        <div class="content">
          <ul>
            <li>
              <label class="custom-label">
                {{ 'DEVICE.SETTINGS.PREFENCE.BRIGHTNESS' | translate }}
                <input 
                  type="number"
                  [readonly]="!isEditing"
                  class="custom-input"
                  formControlName="Brightness"
                  min="10"                 
                  max="100"                    
                  step="1"                     
                />
                <app-control-errors
                  id="valid-Brightness"
                  controlName="Brightness"
                ></app-control-errors>
              </label>
            </li>
            <li>
              <label class="custom-label">
                {{ 'DEVICE.SETTINGS.PREFENCE.SCREEN_SAVER_TIME' | translate }}
                <input 
                  type="number"
                  [readonly]="!isEditing"
                  class="custom-input"
                  formControlName="ScreenSaverTime"
                  min="0"                 
                  max="60"                    
                  step="1"                     
                />
                <app-control-errors
                  id="valid-ScreenSaverTime"
                  controlName="ScreenSaverTime"
                ></app-control-errors>
              </label>
            </li>
            <li>
              <label class="custom-label">
                {{ 'DEVICE.SETTINGS.PREFENCE.BACKUP_ALERT' | translate }}
                <ng-select
                  [items]="enableDisableList"
                  formControlName="BackupAlertDoNotShow"
                  [readonly]="!isEditing"
                  classList="custom-input"
                  bindValue="id"
                  bindLabel="name"
                  [clearable]="false"
                  [searchable]="false"
                  >
                  <ng-template ng-option-tmp ng-label-tmp let-item="item">
                    {{ item.name | translate }}
                  </ng-template>
                </ng-select>
                <app-control-errors
                  id="valid-BackupAlertDoNotShow"
                  controlName="BackupAlertDoNotShow"
                ></app-control-errors>
              </label>
            </li>
            <li>
              <label class="custom-label">
                {{ 'DEVICE.SETTINGS.PREFENCE.COOK_PAUSE' | translate }}
                <ng-select
                  [items]="enableDisableList"
                  formControlName="CookPauseWithUsrCmd"
                  [readonly]="!isEditing"
                  classList="custom-input"
                  bindValue="id"
                  bindLabel="name"
                  [clearable]="false"
                  [searchable]="false"
                  >
                  <ng-template ng-option-tmp ng-label-tmp let-item="item">
                    {{ item.name | translate }}
                  </ng-template>
                </ng-select>
                <app-control-errors
                  id="valid-CookPauseWithUsrCmd"
                  controlName="CookPauseWithUsrCmd"
                ></app-control-errors>
              </label>
            </li>
            <li>
              <label class="custom-label">
                {{ 'DEVICE.SETTINGS.PREFENCE.SHORTCUTS_ENABLED' | translate }}
                <ng-select
                  [items]="enableDisableList"
                  formControlName="ShortcutsEnabled"
                  [readonly]="!isEditing"
                  classList="custom-input"
                  bindValue="id"
                  bindLabel="name"
                  [clearable]="false"
                  [searchable]="false"
                  >
                  <ng-template ng-option-tmp ng-label-tmp let-item="item">
                    {{ item.name | translate }}
                  </ng-template>
                </ng-select>
                <app-control-errors
                  id="valid-ShortcutsEnabled"
                  controlName="ShortcutsEnabled"
                ></app-control-errors>
              </label>
            </li>
            <li>
              <label class="custom-label">
                {{ 'DEVICE.SETTINGS.PREFENCE.VOLTAGE_TYPE' | translate }}
                <ng-select
                  [items]="voltageTypeList"
                  formControlName="VoltageType"
                  [readonly]="!isEditing"
                  classList="custom-input"
                  bindValue="id"
                  bindLabel="name"
                  [clearable]="false"
                  [searchable]="false"
                  >
                  <ng-template ng-option-tmp ng-label-tmp let-item="item">
                    {{ item.name | translate }}
                  </ng-template>
                </ng-select>
                <app-control-errors
                  id="valid-VoltageType"
                  controlName="VoltageType"
                ></app-control-errors>
              </label>
            </li>
          </ul>
        </div>
      </div>
  
      <div class="col-12 col-lg-5 box">
        <div class="title">
          <label>{{ 'DEVICE.SETTINGS.SOUND.TITLE' | translate }}</label>
        </div>
        <div class="content">
          <ul>
            <li>
              <label class="custom-label">
                {{ 'DEVICE.SETTINGS.SOUND.ENABLED' | translate }}
                <ng-select
                  [items]="enableDisableList"
                  formControlName="SoundEnabled"
                  [readonly]="!isEditing"
                  classList="custom-input"
                  bindValue="id"
                  bindLabel="name"
                  [clearable]="false"
                  [searchable]="false"
                  >
                  <ng-template ng-option-tmp ng-label-tmp let-item="item">
                    {{ item.name | translate }}
                  </ng-template>
                </ng-select>
                <app-control-errors
                  id="valid-SoundEnabled"
                  controlName="SoundEnabled"
                ></app-control-errors>
              </label>
            </li>
            <li>
              <label class="custom-label">
                {{ 'DEVICE.SETTINGS.SOUND.AUDIO_VOLUME' | translate }}
                <input 
                  type="number"
                  class="custom-input"
                  formControlName="VolumeAudio"
                  [readonly]="!isEditing"
                  min="0"                 
                  max="100"                    
                  step="1"                     
                />
                <app-control-errors
                  id="valid-VolumeAudio"
                  controlName="VolumeAudio"
                ></app-control-errors>
              </label>
            </li>
            <li>
              <label class="custom-label">
                {{ 'DEVICE.SETTINGS.SOUND.SOUND_ALARM' | translate }}
                <input 
                  type="number"
                  class="custom-input"
                  formControlName="IdxSoundAlarm"
                  [readonly]="!isEditing"
                  min="1"                 
                  max="11"                    
                  step="1"                     
                />
                <app-control-errors
                  id="valid-IdxSoundAlarm"
                  controlName="IdxSoundAlarm"
                ></app-control-errors>
              </label>
            </li>
            <li>
              <label class="custom-label">
                {{ 'DEVICE.SETTINGS.SOUND.SOUND_COOKING_END' | translate }}
                <input 
                  type="number"
                  class="custom-input"
                  formControlName="IdxSoundCookingEnd"
                  [readonly]="!isEditing"
                  min="1"                 
                  max="11"                    
                  step="1"                     
                />
                <app-control-errors
                  id="valid-IdxSoundCookingEnd"
                  controlName="IdxSoundCookingEnd"
                ></app-control-errors>
              </label>
            </li>
            <li>
              <label class="custom-label">
                {{ 'DEVICE.SETTINGS.SOUND.SOUND_ALERT' | translate }}
                <input 
                  type="number"
                  class="custom-input"
                  formControlName="IdxSoundAlert"
                  [readonly]="!isEditing"
                  min="1"                 
                  max="11"                    
                  step="1"                     
                />
                <app-control-errors
                  id="valid-IdxSoundAlert"
                  controlName="IdxSoundAlert"
                ></app-control-errors>
              </label>
            </li>
            <li>
              <label class="custom-label">
                {{ 'DEVICE.SETTINGS.SOUND.STANDBY_SOUND' | translate }}
                <ng-select
                  [items]="enableDisableList"
                  formControlName="CookingStandbySound"
                  [readonly]="!isEditing"
                  classList="custom-input"
                  bindValue="id"
                  bindLabel="name"
                  [clearable]="false"
                  [searchable]="false"
                  >
                  <ng-template ng-option-tmp ng-label-tmp let-item="item">
                    {{ item.name | translate }}
                  </ng-template>
                </ng-select>
                <app-control-errors
                  id="valid-CookingStandbySound"
                  controlName="CookingStandbySound"
                ></app-control-errors>
              </label>
            </li>
          </ul>
        </div>
      </div>
  
      <div class="col-12 col-lg-3 box">
        <div class="title">
          <label>{{ 'COOKING_MODE.HSO' | translate }}</label>
        </div>
        <div class="content">
          <ul>
            <li>
              <label class="custom-label">
                {{ 'DEVICE.SETTINGS.PREFENCE.HOME_EXTENDED_VIEW' | translate }}
                <ng-select
                  [items]="enableDisableList"
                  formControlName="HomeExtendedView"
                  [readonly]="!isEditing"
                  classList="custom-input"
                  bindValue="id"
                  bindLabel="name"
                  [clearable]="false"
                  [searchable]="false"
                  >
                  <ng-template ng-option-tmp ng-label-tmp let-item="item">
                    {{ item.name | translate }}
                  </ng-template>
                </ng-select>
                <app-control-errors
                  id="valid-HomeExtendedView"
                  controlName="HomeExtendedView"
                ></app-control-errors>
              </label>
            </li>
            <li>
              <label class="custom-label">
                {{ 'DEVICE.SETTINGS.PREHEAT_HSO.TEMPERATURE' | translate }}
                <input 
                  type="number"
                  class="custom-input"
                  formControlName="TempPreriscaldoHso"
                  [readonly]="!isEditing"
                  min="100"                 
                  max="250"                    
                  step="1"                     
                />
                <app-control-errors
                  id="valid-TempPreriscaldoHso"
                  controlName="TempPreriscaldoHso"
                ></app-control-errors>
              </label>
            </li>
            <li>
              <label class="custom-label">
                {{ 'DEVICE.SETTINGS.PREHEAT_HSO.PASSWORD' | translate }}
                <input
                  type="text"
                  class="custom-input"
                  formControlName="PasswordPreriscaldoHso"
                  [readonly]="!isEditing"
                />
                <app-control-errors
                  id="validate-PasswordPreriscaldoHso"
                  controlName="PasswordPreriscaldoHso"
                ></app-control-errors>
              </label>
            </li>
          </ul>
        </div>
      </div>
    
      <div class="col-12 col-lg-3 box">
        <div class="title">
          <label>{{ 'DEVICE.SETTINGS.USER_LOCK.TITLE' | translate }}</label>
        </div>
        <div class="content">
          <ul>
            <li>
              <label class="custom-label">
                {{ 'DEVICE.SETTINGS.USER_LOCK.TITLE' | translate }}
                <ng-select
                  [items]="profileLockList"
                  formControlName="BloccoProfilo"
                  [readonly]="!isEditing"
                  classList="custom-input"
                  bindValue="id"
                  bindLabel="name"
                  [clearable]="false"
                  [searchable]="false"
                  >
                  <ng-template ng-option-tmp ng-label-tmp let-item="item">
                    {{ item.name | translate }}
                  </ng-template>
                </ng-select>
                <app-control-errors
                  id="valid-BloccoProfilo"
                  controlName="BloccoProfilo"
                ></app-control-errors>
              </label>
            </li>
            <li>
              <label class="custom-label">
                {{ 'DEVICE.SETTINGS.USER_LOCK.PASSWORD' | translate }}
                <input
                  type="text"
                  class="custom-input"
                  formControlName="PasswordBlocco"
                  [readonly]="!isEditing"
                />
                <app-control-errors
                  id="validate-PasswordBlocco"
                  controlName="PasswordBlocco"
                ></app-control-errors>
              </label>
            </li>
            <li>
              <label class="custom-label">
                {{ 'DEVICE.SETTINGS.USER_LOCK.TIMEOUT' | translate }}
                <input 
                  type="number"
                  [readonly]="!isEditing"
                  class="custom-input"
                  formControlName="BloccoProfiloTimeout"
                  min="0"                 
                  max="180"                    
                  step="1"                     
                />
                <app-control-errors
                  id="valid-BloccoProfiloTimeout"
                  controlName="BloccoProfiloTimeout"
                ></app-control-errors>
              </label>
            </li>
          </ul>
        </div>
      </div>
  
      <div class="col-12 col-lg-3 box">
        <div class="title">
          <label>{{ 'DEVICE.SETTINGS.ASSISTANCE.TITLE' | translate }}</label>
        </div>
        <div class="content">
          <ul>
            <li>
              <label class="custom-label">
                {{ 'DEVICE.SETTINGS.ASSISTANCE.CHEF_SUPPORT' | translate }}
                <input
                  type="text"
                  class="custom-input"
                  formControlName="ChefSupport"
                  [readonly]="!isEditing"
                />
                <app-control-errors
                  id="validate-ChefSupport"
                  controlName="ChefSupport"
                ></app-control-errors>
              </label>
            </li>
            <li>
              <label class="custom-label">
                {{ 'DEVICE.SETTINGS.ASSISTANCE.TECHNICAL_SUPPORT' | translate }}
                <input
                  type="text"
                  class="custom-input"
                  formControlName="TechnicalSupport"
                  [readonly]="!isEditing"
                />
                <app-control-errors
                  id="validate-TechnicalSupport"
                  controlName="TechnicalSupport"
                ></app-control-errors>
              </label>
            </li>
          </ul>
        </div>
      </div>
  
  </div>
</div>

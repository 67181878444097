import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-recipe-name-modal',
  templateUrl: './recipe-name-modal.component.html',
  styleUrls: ['./recipe-name-modal.component.scss']
})
export class RecipeNameModalComponent implements OnInit {
  recipeForm: UntypedFormGroup;
  constructor(private router: Router, public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.recipeForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      multilevel: new UntypedFormControl(false)
    });
  }

  createNewRecipe() {
    this.router.navigate(['/my-naboo', 'new'], {
      queryParams: this.recipeForm.value
    });
    this.activeModal.close();
  }
}

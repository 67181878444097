<div class="input-group mb-3">
  <ng-select
    [items]="values"
    [formControl]="fieldControl"
    classList="custom-input w-100"
    bindValue="key"
    bindLabel="value"
    [clearable]="false"
    [searchable]="false"
  >
  </ng-select>
</div>

import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

export interface ImageSelectorItem {
  src: string,
  value: any,
  disabled: boolean
}

@Component({
  selector: 'app-image-selector',
  templateUrl: './image-selector.component.html',
  styleUrls: ['./image-selector.component.scss']
})
export class ImageSelectorComponent {

  @Input() elements: ImageSelectorItem[] = [];
  @Input() control: AbstractControl<any>


  setControlValue(index: number) { 
    const element = this.elements[index];
    if ( !element.disabled ) {
      this.control.setValue(element.value);
    }
  }

  isSelected(index: number) {
    return this.elements[index].value === this.control.value;
  }

}

<div class="double-indicator-selector d-flex align-items-center">
  <label class="main-label fix-label">
    {{ title }}
  </label>
  <div class="item-selector mx-2">
    <input
      [formControl]="selectorControl"
      type="radio"
      name="btn-check-{{ type }}"
      id="btn-check-{{ type }}-0"
      class="btn-check"
      [checked]="items[0].value === selectorControl.value"
      autocomplete="off"
    />
    <label
      class="btn"
      [ngClass]="
        items[0].value === selectorControl.value
          ? 'button-primary'
          : 'button-outline-primary'
      "
      [for]="'btn-check-' + type + '-0'"
      (click)="changeValue(items[0].value)"
    >
      {{ items[0].label }}
    </label>
    <input
      [formControl]="selectorControl"
      type="radio"
      name="btn-check-{{ type }}"
      id="btn-check-{{ type }}-1"
      class="btn-check"
      [checked]="items[1].value === selectorControl.value"
      autocomplete="off"
    />
    <label
      class="btn"
      [ngClass]="
        items[1].value === selectorControl.value
          ? 'button-primary'
          : 'button-outline-primary'
      "
      [for]="'btn-check-' + type + '-1'"
      (click)="changeValue(items[1].value)"
    >
      {{ items[1].label }}
    </label>
  </div>
</div>

import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-menu-name-modal',
  templateUrl: './menu-name-modal.component.html',
  styleUrls: ['./menu-name-modal.component.scss']
})
export class MenuNameModalComponent implements OnInit {
  menuForm: UntypedFormGroup;
  @Output() onSave: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router, public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.menuForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required)
    });
  }

  createNewRecipe() {
    this.onSave.emit(this.menuForm.value);
    this.activeModal.close();
  }
}

<div class="modal-header">
  <h2>
    {{ modalTitle | translate }}
  </h2>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body p-3">

  <div class="mx-4">
    <app-device-filters
      [datasource]="dataSource"
    ></app-device-filters>
  </div>

  <div class="row mb-2 mt-2 gap-3" [formGroup]="dateRangeForm">
    <div class="col-12 col-lg-6">
      <label>{{ 'DEVICE.TELEMETRY.FROM' | translate }}</label>
      <app-date-time-picker 
        formControlName="dateFrom" 
        name="dateFrom"
        [seconds]="false"
      ></app-date-time-picker> 
    </div>
    <div class="col-12 col-lg-5">
      <label>{{ 'DEVICE.TELEMETRY.TO' | translate }}</label>  
      <app-date-time-picker 
        formControlName="dateTo" 
        name="dateTo"
        [seconds]="false"
      ></app-date-time-picker>   
    </div>
    <app-control-errors
      class="form-group-error"
      controlName="dateRangeForm"
    ></app-control-errors>
  </div>
  
  <app-datatable-paginated
    #table
    [dataSource]="dataSource"
    [columns]="columns"
    [selectionHandler]="selectionHandler"
  ></app-datatable-paginated>

</div>

<div class="note-text">
  <span><span class=".apex-text">*</span>{{ 'DEVICE.SELECTION_NOTE' | translate }}</span>
</div>
<div class="modal-footer">
  <button
    (click)="onConfirm()"
    class="button-primary"
  >
    {{ 'GLOBAL.CONFIRM' | translate }}<span class=".apex-text">*</span>
  </button>
</div>

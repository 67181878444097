<app-recipe-filters
  *ngIf="!isSelecting"
  [isIcs]="lainoxRecipe!"
  [defaultFilter]="defaultFilter"
  (filterChanged)="filtered($event)"
  (filterCleaned)="cleaned()"
></app-recipe-filters>
<div *ngIf="isSelecting" class="selection-bar">
  <div>{{'MY_NABOO.SELECTED_RECIPES'|translate}}: {{selectedRecipesIds.size}}</div>
  <div class="actions">
    <button
      class="button-primary"
      [disabled]="!selectedRecipesIds.size"
      (click)="openDeleteModal(selectionModal)"
    >
      <i class="fa fa-trash"></i>
      {{ 'GLOBAL.DELETE' | translate }}
    </button>

    <button
      class="button-primary"
      [disabled]="!selectedRecipesIds.size"
      (click)="exportSelectedRecipes()"
    >
      <i class="fa fa-cloud-download-alt"></i>
      {{ 'GLOBAL.EXPORT' | translate }}
    </button>
  </div>
</div>
<div id="recipe-box">
  <i
    *ngIf="(datasource.totalItems || 0) > datasource.limit"
    [ngClass]="{ disabled: datasource.page === 0 }"
    class="fa fa-chevron-left"
    (click)="datasource.setPage(datasource.page - 1)"
  ></i>
  <div id="recipe-list" *ngIf="datasource.totalItems! > 0; else noData">
    <div
      *ngFor="let recipe of datasource.currentItems"
      class="recipe-box recipe-animation"
      [style.backgroundImage]="
        'url(' +
        (recipe.icon && recipe.icon.url
          ? recipe.icon.url
          : '/assets/images/recipe_icon_default.png') +
        ')'
      "
      (click)="openRecipeModal(recipe)"
    >
      <input
        *ngIf="isSelecting"
        type="checkbox"
        [checked]="selectedRecipesIds.has(recipe.id!)"
        (click)="onRecipeSelectToggle($event, recipe)"
      >
      <img
        *ngIf="showIconOnRecipe(recipe)"
        [src]="getRecipeIcon(recipe)"
      />
      <div class="recipe-text-box">
        <span #el class="recipe-text" [tooltip]="upperCaseTooltip(recipe)" [isDisabled]="disableTooltip(el)">
          {{ recipe.name }}
        </span>
      </div>
    </div>
  </div>
  <i
    *ngIf="(datasource.totalItems || 0) > datasource.limit"
    [ngClass]="{
      disabled: datasource.page + 1 === datasource.totalItems! / datasource.limit
    }"
    class="fa fa-chevron-right"
    (click)="datasource.setPage(datasource.page + 1)"
  ></i>
</div>
<ngb-pagination
  *ngIf="(datasource.totalItems || 0) > datasource.limit"
  [pageSize]="datasource.limit"
  [page]="datasource.page + 1"
  (pageChange)="datasource.setPage($event - 1)"
  [collectionSize]="datasource.totalItems || 0"
  [maxSize]="5"
></ngb-pagination>
<ng-template #noData>
  <p>{{ 'GLOBAL.NO_DATA' | translate }}</p>
</ng-template>


<ng-template #selectionModal let-modal>
  <div class="modal-header">
    <h2>{{ 'RECIPE.DELETE' | translate }}</h2>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div>{{ 'RECIPE.BULK_DELETE_TITLE' | translate }}:</div>
    <ul class="recipes-names-list">
      <li *ngFor="let name of selectedRecipesNames">{{name}}</li>
    </ul>
  </div>
  <div class="modal-footer">
    <button
      class="button-danger"
      (click)="modal.close()"
    >
      {{ 'GLOBAL.CONFIRM' | translate }}
    </button>
    <button
      (click)="modal.dismiss()"
      class="button-outline-primary"
    >
      {{ 'GLOBAL.CANCEL' | translate }}
    </button>
  </div>

</ng-template>
<div class="modal-header">
  <h2>
    {{ 'MY_DISPLAY.SELECT_DISPLAY' | translate }}
  </h2>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-3" [formGroup]="selectDisplayFormGroup">
  <label class="custom-label" *ngIf="cookingMode">
    {{'RECIPE.COOKING_MODE' | translate }}
    <ng-select
      [items]="cookingMode"
      (change)="onCookingModeChange($event)"
      formControlName="cookingMode"
      classList="custom-input"
      bindValue="id"
      bindLabel="name"
      [clearable]="false"
      [searchable]="false"
      [placeholder]="'RECIPE.COOKING_MODE' | translate"
      >
      <ng-template ng-option-tmp ng-label-tmp let-item="item">
        {{ item.name | translate }}
      </ng-template>
    </ng-select>
    <app-control-errors
      id="cookingMode"
      controlName="cookingMode"
    ></app-control-errors>
  </label>

  <label class="custom-label"
    >{{ 'MY_DISPLAY.DISPLAY' | translate }}
    <ng-select
      [items]="cookingModeDisplay"
      formControlName="display"
      classList="custom-input"
      bindLabel="name"
      [clearable]="false"
      [searchable]="false"
      [placeholder]="'MY_DISPLAY.DISPLAY' | translate"
    >
    </ng-select>
    <app-control-errors
      id="validate-type"
      controlName="display"
    ></app-control-errors
  ></label>
</div>
<div class="modal-footer">
  <button
    [disabled]="!selectDisplayFormGroup.valid"
    (click)="onConfirm()"
    class="button-primary"
  >
    {{ 'GLOBAL.CONFIRM' | translate }}
  </button>
</div>

<div class="modal-body p-3">
  <div class="row">
    <span *ngIf="deviceParams">
      {{ 'GLOBAL.LAST_UPDATE'| translate: { time: (deviceParams.lastUpdate 
        ? (deviceParams.lastUpdate | fromUTCToLocale | date: 'medium':timezone:lang)
        : '') } }}
    </span>
    <div class="table-action-box">
      <button 
        class="button-primary" 
        (click)="getParams()"
        [disabled]="isLoading || isSavingParams"
      >
        <i class="fa fa-sync-alt"></i> 
        {{ 'GLOBAL.UPDATE' | translate }}
      </button>
      <button 
        *ngIf="canEdit()" 
        class="button-primary" 
        (click)="saveParams()"
        [disabled]="isLoading || isSavingParams || !dataSource.items"
      >
        <i class="fa fa-save"></i> 
        {{ 'GLOBAL.SAVE' | translate }}
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <app-datatable
        [dataSource]="dataSource"
        [columns]="columns"
      ></app-datatable>
    </div>
  </div>
</div>

<ng-template #valueTpl let-row="row">
  <input
    type="number"
    [readonly]="!canEdit()"
    class="custom-input"
    [(ngModel)]="row.value"
    [disabled]="isSavingParams"
  />
</ng-template>


import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  DeviceWizardTypeEnum,
  IPairDevice,
  TDeviceWizardType
} from 'src/app/core/device.utils';

@Component({
  selector: 'app-pair-device',
  templateUrl: './pair-device.component.html',
  styleUrls: ['./pair-device.component.scss']
})
export class PairDeviceComponent implements OnInit {
  @Input() modalWizard: TDeviceWizardType = DeviceWizardTypeEnum.serial;

  @Output() pairDevice: EventEmitter<IPairDevice> = new EventEmitter();
  @Output() generateSecureCode: EventEmitter<string> = new EventEmitter();

  serialDeviceForm: UntypedFormGroup;
  secureCodeForm: UntypedFormArray = new UntypedFormArray([]);

  get secureCodeControls() {
    return this.secureCodeForm.controls as UntypedFormControl[];
  }

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.serialDeviceForm = new UntypedFormGroup({
      serialNumber: new UntypedFormControl('', Validators.required)
    });
    Array.from(Array(4).keys()).forEach(() => {
      this.secureCodeForm.push(
        new UntypedFormControl('', [Validators.minLength(1), Validators.maxLength(1)])
      );
    });
  }

  onGenerateSecureCode() {
    if (
      this.serialDeviceForm.valid &&
      this.serialDeviceForm?.get('serialNumber')?.value
    ) {
      this.generateSecureCode.emit(
        this.serialDeviceForm!.get('serialNumber')!.value as string
      );
    }
  }

  onPair() {
    let code = '';
    this.secureCodeControls.forEach((c) => (code += c.value));
    this.pairDevice.emit({
      serialNumber: this.serialDeviceForm!.get('serialNumber')!.value as string,
      secureCode: code
    });
  }
}

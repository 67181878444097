<div class="modal-header">
  <h2>{{ 'INGREDIENT.EDIT_INGREDIENT' | translate }}</h2>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-3" [formGroup]="ingredientFormGroup">
  <label class="custom-label name-label">
    {{ 'INGREDIENT.NAME' | translate }} <br />
    <img *ngIf="ingredient.ics" class="ics-icon" src="assets/images/logo_short.svg" />
    <span class="main-label">{{ ingredientFormGroup.value.name }}</span>
  </label>
  <i *ngIf="canHandleItem()" (click)="editStartingIngredient()" class="fa fa-pen edit-icon"></i>
  <br /><br />
  <div class="row">
    <div class="col-12 col-sm-6">
      <label class="custom-label">
        {{ 'RECIPE.QUANTITY' | translate }}
        <input
          type="text"
          formControlName="quantity"
          class="custom-input small"
          [placeholder]="'RECIPE.QUANTITY' | translate"
        />
      </label>
    </div>
    <div class="col-12 col-sm-6">
      <label class="custom-label">
        {{ 'RECIPE.UM' | translate }}
        <ng-select
          [items]="umList"
          formControlName="um"
          class="small"
          bindValue="id"
          bindLabel="name"
          [clearable]="false"
          [searchable]="false"
          [placeholder]="'RECIPE.UM' | translate"
        >
        </ng-select>
      </label>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    [disabled]="!ingredientFormGroup.valid"
    (click)="editIngredient()"
    class="button-primary"
  >
    {{ 'GLOBAL.EDIT' | translate }}
  </button>
</div>

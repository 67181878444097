<div id="step-list">
  <div id="header" class="d-flex justify-content-end">
    <button *ngIf="isEditing" class="button-primary mt-2" 
        [ngClass]="{'mb-2': controlValue.value.length > 0}" (click)="createNewStep()">
      <i class="fa fa-plus"> </i>{{ 'RECIPE.CREATE_NEW_STEP' | translate }}
    </button>
  </div>
  <p class="my-3" *ngIf="controlValue.value.length === 0">
    {{ 'STEPS.NO_STEPS' | translate }}
  </p>
  <ng-container *ngFor="let step of controlValue.controls; let i = index">
    <app-step
      [step]="singleStep(step)"
      [index]="i"
      [isEditing]="isEditing"
      (deleteStep)="deleteStep(i)"
    ></app-step
  ></ng-container>
</div>

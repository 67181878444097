<div class="modal-header">
  <h2>{{ 'USER_PREFERENCE.MODALS.PAIR_DEVICE.TITLE' | translate }}</h2>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div
  class="modal-body p-3"
  [formGroup]="serialDeviceForm"
  *ngIf="modalWizard === 'SERIAL'"
>
  <label class="custom-label"
    >{{
      'USER_PREFERENCE.MODALS.PAIR_DEVICE.FORM.SERIAL_NUMBER.LABEL' | translate
    }}
    <input
      type="text"
      class="custom-input"
      [placeholder]="
        'USER_PREFERENCE.MODALS.PAIR_DEVICE.FORM.SERIAL_NUMBER.PLACEHOLDER'
          | translate
      "
      formControlName="serialNumber"
    />
  </label>
</div>
<div
  class="modal-body p-3"
  [formGroup]="serialDeviceForm"
  *ngIf="modalWizard === 'SECURE_CODE'"
>
  <label class="custom-label">
    {{
      'USER_PREFERENCE.MODALS.PAIR_DEVICE.FORM.SECURE_CODE.LABEL' | translate
    }}
  </label>
  <div class="d-flex justify-content-center mt-3">
    <ng-container *ngFor="let sc of secureCodeControls; let i = index">
      <input
        type="text"
        class="custom-input pin-input me-2"
        [formControl]="sc"
        maxlength="1"
        appMoveNextByMaxLength
      />
    </ng-container>
  </div>
</div>
<div class="modal-footer">
  <ng-container *ngIf="modalWizard === 'SERIAL'">
    <button
      class="button-primary"
      (click)="onGenerateSecureCode()"
      [disabled]="!serialDeviceForm.valid"
    >
      {{ 'GLOBAL.CONTINUE' | translate }}
    </button>
  </ng-container>
  <ng-container *ngIf="modalWizard === 'SECURE_CODE'">
    <button
      class="button-primary"
      (click)="onPair()"
      [disabled]="!secureCodeForm.valid"
    >
      {{ 'GLOBAL.ADD' | translate }}
    </button>
  </ng-container>
</div>

<div class="modal-header">
  <h2>
    {{ 'USER_PREFERENCE.INSERT_PIN' | translate }}
  </h2>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-3" [formGroup]="settingsPinFormGroup">
  <label class="custom-label"
    >{{ 'GLOBAL.PIN' | translate }}
    <input
      type="password"
      class="custom-input"
      formControlName="password"
      [placeholder]="'GLOBAL.PIN' | translate" />
    <app-control-errors
      id="validate-psw"
      controlName="password"
    ></app-control-errors
  ></label>

  <label class="custom-label" *ngIf="!currentPin"
    >{{ 'GLOBAL.CONFIRM_PIN' | translate }}
    <input
      type="password"
      class="custom-input"
      formControlName="confirmPassword"
      [placeholder]="'GLOBAL.CONFIRM_PIN' | translate" />
    <app-control-errors
      id="validate-confirm-psw"
      controlName="confirmPassword"
    ></app-control-errors
  ></label>
</div>
<div class="modal-footer">
  <button
    [disabled]="!settingsPinFormGroup.valid"
    (click)="handlePin()"
    class="button-primary"
  >
    {{ 'GLOBAL.CONFIRM' | translate }}
  </button>
</div>

import { NgbTimeStruct, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export class DateTimeModel {

  date: NgbDateStruct;
  time: NgbTimeStruct;
  timeZoneOffset: number;

  public constructor( init?: NgbDateStruct | NgbTimeStruct ) {
    this.date = { year: 0, month: 0, day: 0 };
    this.time = { hour: 0, minute: 0, second: 0 };
    this.timeZoneOffset = 0;

    if ( init ) {
      if ( this.isDateStruct(init) ) {
        const dateStruct = init as NgbDateStruct;
        this.date.day = dateStruct.day
        this.date.month = dateStruct.month
        this.date.year = dateStruct.year
      } else {
        const dateStruct = init as NgbTimeStruct;
        this.time.hour = dateStruct.hour
        this.time.minute = dateStruct.minute;
        this.time.second = dateStruct.second;
      }
    }
  }

  private isDateStruct(obj: any): obj is NgbDateStruct {
    return obj && 'year' in obj && 'month' in obj && 'day' in obj;
  }

  public static fromLocalString(dateString: string): DateTimeModel | null {
    const date = new Date(dateString);

    const isValidDate = !isNaN(date.valueOf());

    if (!dateString || !isValidDate) {
      return null;
    }

    const model = new DateTimeModel();
    model.date.year = date.getFullYear();
    model.date.month = date.getMonth() + 1;
    model.date.day = date.getDate();
    model.time.hour = date.getHours();
    model.time.minute = date.getMinutes();
    model.time.second = date.getSeconds();
    model.timeZoneOffset = date.getTimezoneOffset();

    return model;
  }

  private isValidValue(value: any): value is number {
    return (
      typeof value === "number" &&
      isFinite(value) &&
      Math.floor(value) === value &&
      value > 0
    );
  }

  public toString(): string {
    if (
      this.isValidValue(this.date.year) &&
      this.isValidValue(this.date.month) &&
      this.isValidValue(this.date.day)
    ) {
      const year = this.date.year.toString().padStart(2, "0");
      const month = this.date.month.toString().padStart(2, "0");
      const day = this.date.day.toString().padStart(2, "0");

      if (!this.time.hour) {
        this.time.hour = 0;
      }
      if (!this.time.minute) {
        this.time.minute = 0;
      }
      if (!this.time.second) {
        this.time.second = 0;
      }
      if (!this.timeZoneOffset) {
        this.timeZoneOffset = new Date().getTimezoneOffset();
      }

      const hour = this.time.hour.toString().padStart(2, "0");
      const minute = this.time.minute.toString().padStart(2, "0");
      const second = this.time.second.toString().padStart(2, "0");

      const tzo = -this.timeZoneOffset;
      const dif = tzo >= 0 ? "+" : "-",
        pad = function(num: any) {
          const norm = Math.floor(Math.abs(num));
          return (norm < 10 ? "0" : "") + norm;
        };

      const isoString = `${pad(year)}-${pad(month)}-${pad(day)}T${pad(
        hour
      )}:${pad(minute)}:${pad(second)}${dif}${pad(tzo / 60)}:${pad(tzo % 60)}`;

      return isoString;
    }

    return "";
  }

}

<hr *ngIf="isEditing || index != 0" class="hr" />
<div class="single-step">
  <button
    class="button-danger"
    *ngIf="isEditing"
    (click)="deleteStep.emit()"
  >
    <i class="fa fa-times"> </i>{{ 'RECIPE.DELETE' | translate }}
  </button>
  <div class="single-step-info" [formGroup]="step">
    <div class="single-step-imgbox">
      <div *ngIf="isEditing" class="single-step-number">
        STEP {{ index + 1 }}
      </div>
      <app-upload-image
        [imageUrl]="stepImageUrl"
        [isEditing]="isEditing"
        class="single-step-image"
        otherId="recipe-image-{{ index }}"
        (imageChangedBlob)="iconChanged($event)"
      ></app-upload-image>
    </div>
    <div class="single-step-text">
      <ng-container *ngIf="!isEditing">
        <h4>{{ step.value.name }}</h4>
        <p>
          {{ step.value.description }}
        </p>
      </ng-container>
      <ng-container *ngIf="isEditing">
        <label class="custom-label mb-3"
          >{{ 'RECIPE.NAME' | translate }}

          <input
            type="text"
            formControlName="name"
            class="custom-input"
            [placeholder]="'RECIPE.NAME' | translate"
          />
          <app-control-errors
            id="validate-ingredient-name"
            controlName="name"
          ></app-control-errors>
        </label>
        <label class="custom-label"
          >{{ 'RECIPE.STEP_DESCRIPTION' | translate }}
          <textarea
            class="custom-input"
            formControlName="description"
            rows="2"
            [placeholder]="'RECIPE.STEP_DESCRIPTION' | translate"
          ></textarea>
        </label>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="isEditing">
    <h3 class="mt-3 mb-1">{{ 'RECIPE.INGREDIENTS' | translate }}</h3>
    <div id="ingredients-box" [formGroup]="ingredientFormGroup">
      <div class="d-flex align-items-end">
        <label class="custom-label type">
          {{ 'RECIPE.TYPE' | translate }}
          <ng-select
            [items]="typeList"
            formControlName="type"
            classList="custom-input"
            bindValue="id"
            bindLabel="name"
            [clearable]="false"
            [searchable]="false"
            [placeholder]="'RECIPE.TYPE' | translate"
          >
          </ng-select>
        </label>
        <label class="custom-label">
          {{ 'RECIPE.ADD_INGREDIENT' | translate }}
          <ng-select
            [items]="ingredientListFiltered"
            bindValue="id"
            bindLabel="name"
            formControlName="id"
            [clearable]="true"
            [searchable]="true"
            [placeholder]="'RECIPE.ADD_INGREDIENT' | translate"
          >
            <ng-template ng-option-tmp ng-label-tmp let-item="item">
              <img *ngIf="item.ics" class="ics-icon" src="assets/images/logo_short.svg" />
              {{ item.name }}
            </ng-template>
          </ng-select>
        </label>

        <label class="custom-label">
          {{ 'RECIPE.QUANTITY' | translate }}
          <input
            type="text"
            formControlName="quantity"
            class="custom-input small"
            [placeholder]="'RECIPE.QUANTITY' | translate"
          />
        </label>
        <label class="custom-label">
          {{ 'RECIPE.UM' | translate }}
          <ng-select
            [items]="umList"
            formControlName="um"
            class="small"
            bindValue="id"
            bindLabel="name"
            [clearable]="true"
            [searchable]="true"
            [placeholder]="'RECIPE.UM' | translate"
          >
          </ng-select>
        </label>
        <button class="button-primary" (click)="addIngredient()">
          <i class="fa fa-plus"></i>
        </button>
      </div>
      <button class="button-primary" (click)="createNewIngredient()">
        <i class="fa fa-plus"> </i
        >{{ 'RECIPE.CREATE_NEW_INGREDIENT' | translate }}
      </button>
    </div>
  </ng-container>

  <app-datatable
    #table
    [dataSource]="dataSource"
    [columns]="columns"
    [hidePagination]="true"
  ></app-datatable>
</div>

<ng-template #actionTpl let-row="row" let-rowIndex="rowIndex">
  <i
    *ngIf="isEditing"
    (click)="editIngredient(row, rowIndex)"
    class="table-icons fa fa-fw fa-pen"
  ></i>
  <i
    *ngIf="isEditing"
    (click)="deleteIngredient(rowIndex)"
    class="table-icons fa fa-fw fa-times"
  ></i>
</ng-template>

<ng-template #allergensTpl let-value="value">
  <span *ngIf="value.lengh === 0">--</span>
  <span *ngFor="let allergen of value; let i = index"
    >{{ allergen.name }} {{ i === value.length - 1 ? '' : ', ' }}</span
  >
</ng-template>

<ng-template #nameTpl let-row="row" let-rowIndex="rowIndex">
  <img *ngIf="row.ics" class="ics-icon" src="assets/images/logo_short.svg" />
  <span>{{ row.name }}</span>
</ng-template>

import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { RecipeBoDtoNBK } from 'src/app/api/nbk';
import { deltaTempConfiguration, EC0_VAPOR_TYPE_TRESHOLD, IConfigSliderCookingStep, NabooWithSpilloneCookingType, NeoCookingType, NeoWithSpilloneCookingType, 
  spilloneConfiguration, TEMP_TRESHOLD_NABOO, temperatureConfiguration, 
  TURBO_VAPOR_TYPE_TRESHOLD} from 'src/app/core/cooking-step.utils';
import { PhaseControlTypeEnum } from 'src/app/core/cooking-step.utils';
import { RecipeDetailService } from '../../../recipe-detail.service';
import { ISectionList } from '../edit-cooking-steps-modal.component';
import { PhaseSwitcher } from '../../cooking-steps.handler';

@Component({
  selector: 'app-phase-tab-naboo',
  templateUrl: './phase-tab-naboo.component.html',
  styleUrls: ['./phase-tab-naboo.component.scss']
})
export class PhaseTabNabooComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fgroup: UntypedFormGroup;
  @Input() cookingMode: RecipeBoDtoNBK.CookingModeEnum;
  @Input() deviceModel: RecipeBoDtoNBK.DeviceModelEnum;
  @Input() sectionList: ISectionList[] = [];
  @Input() tabType: string;
  @Input() tempUnit: 'C' | 'F';
  @Input() phaseIndex: number;
  @Input() firstPhase: boolean = false;
  @Input() switcher: PhaseSwitcher;

  onlyForNaboo = false;

  sectionTypeList = PhaseControlTypeEnum;


  get tempControl() {
    return (this.fgroup.controls['cookingSettings'] as UntypedFormGroup).controls['cameraTemperature'] as UntypedFormControl;
  }
  get deltaTempControl() {
    return (this.fgroup.controls['cookingSettings'] as UntypedFormGroup).controls['deltaTemperature'] as UntypedFormControl;
  }
  get timeControl() {
    return (this.fgroup.controls['cookingSettings'] as UntypedFormGroup).controls['timer'] as UntypedFormControl;
  }
  get spilloneTempControl() {
    return (this.fgroup.controls['cookingSettings'] as UntypedFormGroup).controls['spilloneTemperature'] as UntypedFormControl;
  }
  get autoclimateControl() {
    return this.fgroup.controls['autoclimate'] as UntypedFormControl;
  }
  get vaporTypeControl() {
    return this.fgroup.controls['vaporType'] as UntypedFormControl;
  }
  get fanTypeControl() {
    return (this.fgroup.controls['fan'] as UntypedFormGroup).controls['type'] as UntypedFormControl;
  }
  get fanControl() {
    return (this.fgroup.controls['fan'] as UntypedFormGroup).controls['speed'] as UntypedFormControl;
    
  }
  get vaporControl() {
    return this.fgroup.controls['vaporType'] as UntypedFormGroup;
    
  }

  temperatureSetup: IConfigSliderCookingStep = {
    ceil: 45,
    floor: -40,
    minLimit: -40
  };

  deltaTempSetup: IConfigSliderCookingStep = {
    ceil: 45,
    floor: -40,
    minLimit: -40
  };

  spilloneSetup: IConfigSliderCookingStep = {
    ceil: 150,
    floor: 1,
    minLimit: 1
  };

  timeSetup = {
    ceil: 86400,
    floor: 0,
    step: 60,
    time: true,
    firstStep: 60
  };
  
  autoclimateSetup = {
    ceil: 100,
    floor: 0,
    step: 1,
    firstStep: 15
  };

  vaporTypeValues = [
    {
      src: 'assets/images/nabook/btn_ecovapor.png',
      value: 'ECO',
      disabled: false
    }, 
    {
      src: 'assets/images/nabook/btn_turbovapor.png',
      value: 'TURBO',
      disabled: true
    }
  ]

  fanSpedValues = [1,2,3,4,5,6]

  unsubscribe$: Subject<void> = new Subject();

  constructor(private recipeDetailService: RecipeDetailService) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(): void {
    switch (this.tabType) {
      case 'CONVECTION':
        // if (this.firstPhase) {
        //   this.recipeDetailService.enablePreheat();
        //   this.initPreheat.emit();
        // }
        // this.tempControl.enable();
        // this.microwaveControl.disable();

        // this.setTempValue(this.tempControl.value);
        // this.setMicrowaveValue();

        break;
      case 'MICROWAVE':
        // if (this.firstPhase) {
        //   this.recipeDetailService.disabledPreheat(
        //     this.cookingMode,
        //     this.tempUnit
        //   );
        // }
        // this.tempControl.disable();
        // this.microwaveControl.enable();
        
        // this.setTempValue();
        // this.setMicrowaveValue(this.microwaveControl.value);

        break;
      case 'COMBO':
        // if (this.firstPhase) {
        //   this.recipeDetailService.enablePreheat();
        // }
        // this.tempControl.enable();
        
        // this.setTempValue(this.tempControl.value);

        break;
      default:
        break;
    }
  }

  ngOnInit(): void {
    this.setTempValue(this.tempControl.value);
    this.temperatureSetup = temperatureConfiguration(this.cookingMode,this.tempUnit, this.tabType as NeoCookingType);
    this.spilloneSetup = spilloneConfiguration(this.cookingMode,this.tempUnit, this.tabType as NabooWithSpilloneCookingType)!
    this.deltaTempSetup = deltaTempConfiguration(this.cookingMode,this.tempUnit, this.tabType as NabooWithSpilloneCookingType)!;
    this.disableSoftDry();
  }


  setTempValue(number?: number) {
    this.tempControl.setValue(
      this.tempControl.disabled || !number || number < this.temperatureSetup.minLimit!
      ? this.temperatureSetup.minLimit!
      : number
    );
  }

  showControl(sectionType: PhaseControlTypeEnum) {
    const control = this.sectionList.find((s) => s.label === sectionType );
    return control && ( control?.disabled === false ||
      ( Array.isArray(control.disabled) && !(control?.disabled as number[]).includes( this.phaseIndex ) ) );
  }

  setUseSpillone( value: boolean ) {
    this.switcher.useSpillone = value;
    if ( !value ) {
      this.switcher.useDeltaTemperature = false;
    }
  }

  setUseDeltaTemperature( value: boolean ) {
    this.switcher.useDeltaTemperature = value;
  }

  setUseAlternateFan( value: boolean ) {
    this.switcher.useAlternateFan = value;
    this.fanTypeControl.setValue( value ? 'NORMAL' : 'ALTERNATING' );
  }

  onTemperatureChage(event: any) {
    if ( this.spilloneTempControl.value < event ) {
      const spilloneValue = event + 2;
      if ( spilloneValue > this.spilloneSetup.ceil!  ) {
        this.spilloneTempControl.setValue(this.spilloneSetup.ceil);
      } else {
        this.spilloneTempControl.setValue(spilloneValue);
        this.spilloneSetup.floor = spilloneValue;
        this.spilloneSetup.minLimit = spilloneValue;
      }
    }

    this.onlyForNabooHandler(event);
    this.vaporTypeHandler(event);

  }

  private vaporTypeHandler(event: any) {
    this.onlyForNaboo = event > TEMP_TRESHOLD_NABOO;
    if (event > TURBO_VAPOR_TYPE_TRESHOLD) {
      this.fgroup.controls['vaporType'].setValue('TURBO')
      this.vaporTypeValues.forEach(e => e.disabled = e.value === 'ECO');
    } else if ( event < EC0_VAPOR_TYPE_TRESHOLD ) {
      this.fgroup.controls['vaporType'].setValue('ECO');
      this.vaporTypeValues.forEach(e => e.disabled = e.value === 'TURBO');
    } else {
      this.vaporTypeValues.forEach( e => e.disabled = false );
    }
  }

  private onlyForNabooHandler(event: any) {
    this.onlyForNaboo = event > TEMP_TRESHOLD_NABOO;
    if (this.onlyForNaboo) {
      this.fgroup.controls['moisturizing'].disable();
      this.fgroup.controls['moisturizing'].setValue(0);
    } else {
      this.fgroup.controls['moisturizing'].enable();
    }
  }

  private disableSoftDry() {
    this.fgroup.get('autoclimate')?.valueChanges.subscribe((value: number) => {
      if (value > 0) {
        this.fgroup.controls['softDry'].disable();
        this.fgroup.controls['softDry'].setValue(false);
      } else {
        this.fgroup.controls['softDry'].enable();
      }
    });
  }

}

<div class="modal-header">
  <h2>{{ 'DEVICES.DEVICE_CARD.MODALS.EDIT_DEVICE.TITLE' | translate }}</h2>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-3" [formGroup]="deviceNameForm">
  <label class="custom-label"
    >{{
      'DEVICES.DEVICE_CARD.MODALS.EDIT_DEVICE.FORM.DEVICE_NAME.LABEL' | translate
    }}
    <input
      type="text"
      class="custom-input"
      [placeholder]="
        'DEVICES.DEVICE_CARD.MODALS.EDIT_DEVICE.FORM.DEVICE_NAME.PLACEHOLDER'
          | translate
      "
      formControlName="deviceName"
    />
  </label>
</div>
<div class="modal-footer">
    <button
    class="button-secondary"
    (click)="activeModal.dismiss('Close click')"
  >
    {{ 'GLOBAL.CANCEL' | translate }}
  </button>
  <button
    class="button-primary"
    (click)="edit()"
    [disabled]="!deviceNameForm.valid"
  >
    {{ 'GLOBAL.SAVE' | translate }}
  </button>
</div>

import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ODataFiltrableDataSource } from 'filtrable-data-source';
import { ToastrService } from 'ngx-toastr';
import { take, takeUntil, tap } from 'rxjs/operators';
import {
  DisplayListBoDtoNBK,
  DisplayService,
  MyNabooService,
  RecipeBoDtoNBK,
  RecipeService,
  UserKeycloakDtoNBK,
  UserService
} from 'src/app/api/nbk';
import { DefaultFilter, disableTooltip, getFileNameFromHttpHeader, getUserDisplayMode, KEY_LANGUAGE_STORAGE, upperCaseTooltip } from 'src/app/core/utils';
import { RecipeActionsModalComponent } from '../recipe-actions-modal/recipe-actions-modal.component';
import { RecipeFiltersService } from '../recipe-filters/recipe-filters.service';
import { Subject } from 'rxjs';
import { DisplaySelectionModal } from '../modals/display-selection-modal/display-selection-modal.component';
import { RecipesHandler } from './recipes.handler';
import { OptionsModalState } from '../modals/options-modal/options-modal.component';
import { ModalService } from '../modals/modal.service';
import { HttpResponse } from '@angular/common/http';
import { getMultipleExportOptions, haveAllSameDeviceModel } from 'src/app/core/recipe.utils';

export type DefaultRecipeFilterEnum = 'noIngredients' | 'noCookingStep';

interface EntityResponse {
  id: number;
  message: string;
}

@Component({
  selector: 'app-recipes',
  templateUrl: './recipes.component.html',
  styleUrls: ['./recipes.component.scss']
})
export class RecipesComponent implements OnInit, AfterContentChecked {
  defaultFilter: DefaultFilter;

  @Input() filterNamePrefix?: string = '';
  @Input() lainoxRecipe?: boolean = false;
  @Input() datasource: ODataFiltrableDataSource<RecipeBoDtoNBK>;
  
  @Input() set selectEnabled(value: boolean) {
    this.isSelecting = value;
    this.selectedRecipesIds = new Map();
  }
  
  @Output() onRefreshData: EventEmitter<void> = new EventEmitter();
  @Output() onToggleSelectEnabled: EventEmitter<boolean> = new EventEmitter();
  
  kcUser: UserKeycloakDtoNBK;

  isSelecting = false;
  selectedRecipesIds: Map<number,RecipeBoDtoNBK> = new Map();
  selectedRecipesNames: String[] = [];

  unsubscribe$: Subject<void> = new Subject();

  constructor(
    private userService: UserService,
    private modalHandler: NgbModal,
    private modalService: ModalService,
    private myNabooService: MyNabooService,
    private toastrService: ToastrService,
    private ts: TranslateService,
    private myDisplayService: DisplayService,
    private recipeService: RecipeService,
    private route: ActivatedRoute,
    private recipesFiltersService: RecipeFiltersService,
    private recipeHandler: RecipesHandler,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params) {
        const filter: DefaultRecipeFilterEnum = params.filter;
        this.defaultFilter = {
          filter: filter,
          value: true
        };
      } else {
        this.defaultFilter = {};
      }
    });

    this.userService
    .getLoggedUser()
    .pipe(
      takeUntil(this.unsubscribe$),
      tap((res) => {
        this.kcUser = res;
      })
    )
    .subscribe();
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  filtered(val: any) {
    this.recipesFiltersService.setCustomFilter(
      this.filterNamePrefix,
      this.datasource,
      this.lainoxRecipe!
    );
    this.datasource.applyFilters();
  }

  cleaned() {
    this.recipesFiltersService.resetFilter(
      this.filterNamePrefix,
      this.datasource,
      this.lainoxRecipe!
    );
    this.datasource.applyFilters();
  }

  openRecipeModal(recipe: any) {
    if (this.isSelecting) {
      this.onRecipeSelectToggle(
        { stopPropagation: () => {} },
        recipe
      );
      return;
    }
    const modalRef = this.modalHandler.open(RecipeActionsModalComponent, {
      modalDialogClass: 'modal-lg'
    });
    modalRef.componentInstance.lainoxRecipe = this.lainoxRecipe;
    modalRef.componentInstance.recipe = recipe;
    modalRef.componentInstance.onAddToMyNaboo
      .pipe(take(1))
      .subscribe((recipe: RecipeBoDtoNBK) => {
        this.addToMyNaboo(recipe);
      });
    modalRef.componentInstance.onRemoveFromMyNaboo
      .pipe(take(1))
      .subscribe((recipe: RecipeBoDtoNBK) => {
        this.removeFromMyNaboo(recipe);
      });
    modalRef.componentInstance.onAddToMyDisplay
      .pipe(take(1))
      .subscribe((recipe: RecipeBoDtoNBK) => {
        this.handleAddToMyDisplay(recipe);
      });
    modalRef.componentInstance.onCopyRecipe
      .pipe(take(1))
      .subscribe((recipe: RecipeBoDtoNBK) => {
        this.copyRecipe(recipe);
      });
    modalRef.componentInstance.onDeleteRecipe
      .pipe(take(1))
      .subscribe((recipe: RecipeBoDtoNBK) => {
        this.deleteRecipe(recipe);
      });
    modalRef.componentInstance.onExportRecipe
      .pipe(take(1))
      .subscribe((recipe: RecipeBoDtoNBK) => {
        this.exportRecipe(recipe);
      });
  }

  addToMyNaboo(recipe: RecipeBoDtoNBK) {
    this.myNabooService
      .addToMyNaboo(recipe.id!)
      .pipe(take(1))
      .subscribe(() => {
        this.toastrService.success(
          this.ts.instant('RECIPE.ADDED_TO_MYNABOO'),
          this.ts.instant('GLOBAL.SUCCESS')
        );
      });
  }

  removeFromMyNaboo(recipe: RecipeBoDtoNBK) {
    this.myNabooService
      .deleteFromMyNaboo(recipe.id!)
      .pipe(take(1))
      .subscribe(() => {
        this.toastrService.success(
          this.ts.instant('RECIPE.REMOVED_FROM_MYNABOO'),
          this.ts.instant('GLOBAL.SUCCESS')
        );

        this.onRefreshData.emit();
      });
  }

  handleAddToMyDisplay(recipe: RecipeBoDtoNBK) {
    this.myDisplayService
      .getAllDisplays(localStorage.getItem(KEY_LANGUAGE_STORAGE)!, undefined, undefined, true, undefined,
        `mode eq '${getUserDisplayMode(this.kcUser)}' 
        and deviceModel eq '${recipe.deviceModel}' and cookingMode eq '${recipe.cookingMode}'`)
      .pipe(take(1))
      .subscribe((d) => {
        if (d.value && d.value.length > 0) {
          if ( d.count === 1 ) {
            this.addToMyDisplay(d.value[0]!.id!, recipe.id!);
          } else {
            this.handleDisplaySelection(d.value, recipe.id!);
          }
        }
      });
  }

  private addToMyDisplay(displayId: number, recipeId: number) {
    this.myDisplayService
      .addRecipeToDisplay(displayId, recipeId, 0)
      .pipe(take(1))
      .subscribe(() => {
        this.toastrService.success(
          this.ts.instant('RECIPE.ADDED_TO_MYDISPLAY'),
          this.ts.instant('GLOBAL.SUCCESS')
        );
      });
  }

  handleDisplaySelection( displays: DisplayListBoDtoNBK[], recipeId: number ) {
    const modalRef = this.modalHandler.open(DisplaySelectionModal, {
      backdrop: 'static',
      modalDialogClass: 'modal-md'
    });
    modalRef.componentInstance.displays = displays;
    modalRef.componentInstance.displaySelected.subscribe((display: DisplayListBoDtoNBK) => {
      this.addToMyDisplay(display.id!, recipeId);
    });
  }

  copyRecipe(recipe: RecipeBoDtoNBK) {
    this.recipeService
      .duplicateRecipe(recipe.id!, sessionStorage.getItem('language')!)
      .pipe(take(1))
      .subscribe(() => {
        this.toastrService.success(
          this.ts.instant('RECIPE.DUPLICATED'),
          this.ts.instant('GLOBAL.SUCCESS')
        );

        this.onRefreshData.emit();
      });
  }

  deleteRecipe(recipe: RecipeBoDtoNBK) {
    this.recipeService
      .deleteRecipe(recipe.id!)
      .pipe(take(1))
      .subscribe(() => {
        this.toastrService.success(
          this.ts.instant('RECIPE.DELETED'),
          this.ts.instant('GLOBAL.SUCCESS')
        );

        this.onRefreshData.emit();
      });
  }

  onRecipeSelectToggle(event: any, recipe: RecipeBoDtoNBK): void {
    event.stopPropagation();
    if (this.selectedRecipesIds.has(recipe.id!)) {
      this.selectedRecipesIds.delete(recipe.id!);
    } else {
      this.selectedRecipesIds.set(recipe.id!, recipe);
    }
  }

  openDeleteModal(content: any): void {
    this.selectedRecipesNames = Array.from( this.selectedRecipesIds.values() ).map( r => r.name! );
    this.modalHandler.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      () => {
        this.recipeService.bulkDeleteRecipe({
          ids: Array.from( this.selectedRecipesIds.keys() )
        })
        .pipe(take(1))
        .subscribe((data: any) => {
          const convertedData : EntityResponse[] = data as Array<EntityResponse>;
          this.isSelecting = false;

          if ( !convertedData || convertedData.length === 0 ) {
            this.toastrService.success(
              this.ts.instant('RECIPE.DELETED'),
              this.ts.instant('GLOBAL.SUCCESS')
            );
          } else {
            this.toastrService.warning(
              this.ts.instant('RECIPE.BULK_DELETE_WARNING', {
                RECIPES_NAME: '&#x2022; ' + convertedData.map( e => this.selectedRecipesIds.get(e.id) ).join('<br/>&ensp;&ensp;&#x2022; ')
              }),
              this.ts.instant('GLOBAL.WARNING'),
              { enableHtml: true }
            )
          }

          this.onToggleSelectEnabled.emit(false);
          this.onRefreshData.emit();
        });
      },
      () => {}
    );
  }


  exportRecipe(recipe: RecipeBoDtoNBK) {
    this.recipeService
      .exportRecipes(localStorage.getItem(KEY_LANGUAGE_STORAGE)!, {
        ids: [recipe.id!]
      }, "response")
      .pipe(take(1))
      .subscribe((data: HttpResponse<any>) => {
        this.onDownloadSuccess(data);
      });
  }

  exportSelectedRecipes() {
    const sameDeviceModel = haveAllSameDeviceModel(Array.from( this.selectedRecipesIds.values() ));
    if ( sameDeviceModel ) {
      this.exportMultiples(Array.from( this.selectedRecipesIds.keys() ));
    } else {
      const modalInfo: OptionsModalState = {
        title: this.ts.instant('GLOBAL.EXPORT'),
        message: this.ts.instant('RECIPES.EXPORT.HINT'),
        options: getMultipleExportOptions(Array.from(this.selectedRecipesIds.values())),
        preventClose: true
      };
  
      this.modalService.showOptionsModal(modalInfo).subscribe((s : any) => {
        const toExport = Array.from( this.selectedRecipesIds.values() )
          .filter( r => r.deviceModel === s as RecipeBoDtoNBK.DeviceModelEnum).map( r => r.id! );
        this.exportMultiples(toExport);
      });
    }
  }

  private exportMultiples(toExport: number[]) {
    this.recipeService
      .exportRecipes(localStorage.getItem(KEY_LANGUAGE_STORAGE)!, {
        ids: toExport,
      }, "response")
      .pipe(take(1))
      .subscribe((data: HttpResponse<any>) => {
        this.onDownloadSuccess(data);
        this.isSelecting = false;
        this.onToggleSelectEnabled.emit(false);
      });
  }

  private onDownloadSuccess(data: HttpResponse<any>) {
    const fileName = getFileNameFromHttpHeader(data);
    this.toastrService.success(
      this.ts.instant('RECIPE.EXPORTED'),
      this.ts.instant('GLOBAL.SUCCESS')
    );
    const url = window.URL.createObjectURL(data.body);
    var fileLink = document.createElement('a');
    fileLink.href = url;
    fileLink.download = fileName;
    fileLink.click();
  }

  showIconOnRecipe(recipe: RecipeBoDtoNBK) {
    return this.recipeHandler.showIconOnRecipe(recipe);
  }

  getRecipeIcon(recipe: RecipeBoDtoNBK) {
    return this.recipeHandler.getRecipeIcon(recipe);
  }

  upperCaseTooltip(recipe: RecipeBoDtoNBK) {
    return upperCaseTooltip(recipe.name);
  }

  disableTooltip(el: HTMLSpanElement) {
    return disableTooltip(el);
  }

}

<div class="menu-box">
  <i class="device-menu fa fa-ellipsis-v" (click)="toggleModalAction()"></i>
  <div class="modal-action" *ngIf="showModal">
    <ul>
      <!-- <li (click)="openDetail(menu, false)">Visualizza</li> -->
      <li (click)="openDetail(menu, true)">{{ 'MENU.EDIT' | translate }}</li>
      <li (click)="deleteMenu(menu)">{{ 'MENU.DELETE' | translate }}</li>
    </ul>
  </div>
  <div class="menu-title">{{ menu.name }}</div>
  <!-- <div class="menu-info">
    <label>{{ "MENU.LAST_UPDATE" | translate }}</label> <span>{{ menu.lastUpdate }}</span>
  </div> -->
</div>

<ng-container *ngIf="tabType === 'LEAVENING'">
  <div class="row mx-0">
    <div class="col-12 section-box">
      <div class="icon-box">
        <img class="selected" src="assets/images/nabook/temp_snow.png" alt="Temperature" />
      </div>
      <div>
        <app-slider
          [control]="tempControl"
          [tempUnit]="tempUnit"
          [temperature]="true"
          [ceil]="temperatureSetup.ceil!"
          [floor]="temperatureSetup.floor"
          [minLimit]="temperatureSetup.minLimit"
          [color]="'frozen'"
          [step]="1"
          [showButton]="true"
        ></app-slider>
      </div>
    </div>
    <div class="col-12 section-box" 
      *ngIf="showControl(sectionTypeList.Time)"
    >
      <div class="icon-box">
        <img class="selected" src="assets/images/time.png" alt="Time icona" />
      </div>
      <div>
        <app-slider
          *ngIf="selectedTime === 10"
          [control]="timeControl"
          [ceil]="timeSetup.ceil"
          [floor]="timeSetup.floor"
          [step]="timeSetup.step"
          [color]="'orange'"
          [time]="timeSetup.time"
          [showButton]="true"
          [firstStep]="timeSetup.firstStep"
        ></app-slider>
      </div>
    </div>
    <div class="col-12 section-box"
      *ngIf="showControl(sectionTypeList.Autoclimate)"
    >
      <div class="icon-box">
        <img src="assets/images/nabook/cm_btn_autoclima.png" alt="autoclimate" class="selected" />
      </div>
      <div>
        <app-slider
          [control]="autoclimateControl"
          [ceil]="autoclimateSetup.ceil"
          [floor]="autoclimateSetup.floor"
          [step]="autoclimateSetup.step"
          [color]="'frozen'"
          [percentage]="true"
          [showButton]="true"
          [firstStep]="autoclimateSetup.firstStep"
        ></app-slider>
      </div>
    </div>
    <div class="col-12 section-box" >
      <div class="icon-box">
        <img class="selected" src="assets/images/fan-no.png" alt="fan" />
      </div>
      <div>
        <app-dot-selector
          [elements]="fanSpedValues"
          [color]="'#f9b100'"
          [control]="fanControl"
        ></app-dot-selector>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="tabType !== 'LEAVENING'">
  <div class="row mx-0">
    <div class="col-12 section-box">
      <div class="icon-box">
        <img class="selected" [src]="getTemperatureImage()" alt="Temperature" />
      </div>
      <div>
        <app-slider
          [control]="tempControl"
          [tempUnit]="tempUnit"
          [temperature]="true"
          [ceil]="temperatureSetup.ceil!"
          [floor]="temperatureSetup.floor"
          [minLimit]="temperatureSetup.minLimit"
          [color]="getTemperatureColor()"
          [step]="1"
          [showButton]="true"
          (valueChanged)="onTemperatureChage($event)"
        ></app-slider>
      </div>
    </div>
    <div class="col-12 section-box" 
      *ngIf="showControl(sectionTypeList.Time)"
    >
      <div class="switch-icon-box">
        <div class="icon-box">
          <img 
            [ngClass]="{ 'selected': switcher.useSpillone}"
            src="assets/images/nabook/cm_btn_sonda_temp.png" alt="Spillpne" (click)="setUseSpillone(true)" />
        </div>
        <div class="icon-box">
          <img 
            [ngClass]="{ 'selected': !switcher.useSpillone}"
            src="assets/images/time.png" alt="Time" (click)="setUseSpillone(false)" />
        </div>
      </div>
      <div>
        <app-slider
          *ngIf="!switcher.useSpillone"
          [control]="timeControl"
          [ceil]="timeSetup.ceil"
          [floor]="timeSetup.floor"
          [step]="timeSetup.step"
          [color]="'orange'"
          [time]="timeSetup.time"
          [showButton]="true"
          [firstStep]="timeSetup.firstStep"
        ></app-slider>
        <app-slider
          *ngIf="switcher.useSpillone"
          [control]="spilloneTempControl"
          [tempUnit]="tempUnit"
          [temperature]="true"
          [ceil]="spilloneSetup.ceil!"
          [floor]="spilloneSetup.floor"
          [minLimit]="spilloneSetup.minLimit"
          [color]="'orange'"
          [step]="1"
          [showButton]="true"
        ></app-slider>
      </div>
    </div>
    <div class="col-12 section-box"
      *ngIf="showControl(sectionTypeList.Autoclimate)"
    >
      <div class="icon-box">
        <img src="assets/images/nabook/cm_btn_autoclima.png" alt="autoclimate" class="selected" />
      </div>
      <div>
        <app-slider
          [control]="autoclimateControl"
          [ceil]="autoclimateSetup.ceil"
          [floor]="autoclimateSetup.floor"
          [step]="autoclimateSetup.step"
          [color]="'frozen'"
          [percentage]="true"
          [showButton]="true"
          [firstStep]="autoclimateSetup.firstStep"
        ></app-slider>
      </div>
    </div>
    <div class="col-12 section-box" >
      <div class="icon-box">
        <img class="selected" src="assets/images/fan-no.png" alt="fan" />
      </div>
      <div>
        <app-dot-selector
          [elements]="fanSpedValues"
          [color]="'#f9b100'"
          [control]="fanControl"
        ></app-dot-selector>
      </div>
    </div>
  </div>
</ng-container>


import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import {
  contains,
  filterOperatorType,
  FiltrableDataSource,
  not
} from 'filtrable-data-source';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  FiltersStorageService,
  FilterStorageState
} from '../filters-storage.service';
import { FiltersService } from '../filters.service';

@Component({
  selector: 'app-data-source-search-field',
  templateUrl: './data-source-search-field.component.html',
  styleUrls: ['./data-source-search-field.component.scss']
})
export class DataSourceSearchFieldComponent implements OnInit, OnDestroy {
  @Input() dataSource: FiltrableDataSource<any>;
  @Input() field: string;
  @Input() placeholder: string;
  @Input() fieldControl = new UntypedFormControl(undefined);
  @Input() applyOnChanges: boolean = true;

  operator: filterOperatorType = 'contains';
  private subscription: Subscription;

  constructor(
    private router: Router,
    private filtersStorageService: FiltersStorageService,
    private filtersService: FiltersService
  ) {}

  ngOnInit(): void {
    const DEBOUNCE_TIME = this.applyOnChanges ? 700 : 0;

    this.fieldControl.valueChanges
      .pipe(debounceTime(DEBOUNCE_TIME))
      .subscribe((val) => {
        const searchedValue = val || '';
        if (searchedValue) {
          this.addFilter();
        } else {
          this.removeFilter();
        }
      });

    this.initFilterValue();

    this.subscription = this.filtersService.filtersCleared.subscribe(
      () => (this.operator = 'contains')
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  changeOperator(): void {
    this.operator = this.operator === 'contains' ? 'not' : 'contains';
    this.dataSource.removeFilter(this.field);
    this.addFilter();
  }

  private initFilterValue(): void {
    const filterValue = this.filtersStorageService.getItem(
      this.router.url,
      this.field
    );

    if (filterValue.operator || filterValue.value) {
      this.fieldControl.setValue(filterValue.value);
      this.operator = filterValue.operator ? filterValue.operator : 'contains';
    }
  }

  private addFilter(): void {
    if (this.operator === 'contains') {
      this.dataSource.setFilter(this.field, contains(this.fieldControl.value));
    } else if (this.operator === 'not') {
      this.dataSource.setFilter(
        this.field,
        not(contains(this.fieldControl.value))
      );
    }
    if (this.applyOnChanges) {
      this.dataSource.applyFilters();
    }

    this.saveFilterInStorage();
    this.filtersService.addFilters();
  }

  private removeFilter(): void {
    this.dataSource.removeFilter(this.field);
    if (this.applyOnChanges) {
      this.dataSource.applyFilters();
    }
    this.filtersStorageService.removeItem(this.router.url, this.field);
    this.filtersService.removeFilters();
  }

  private saveFilterInStorage(): void {
    const filterState: FilterStorageState = {
      value: this.fieldControl.value,
      operator: this.operator
    };
    this.filtersStorageService.setItem(
      this.router.url,
      this.field,
      filterState
    );
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-device-modal',
  templateUrl: './edit-device-modal.component.html',
  styleUrls: ['./edit-device-modal.component.scss']
})
export class EditDeviceModalComponent implements OnInit {
  @Output() updateName = new EventEmitter();
  deviceName: string;
  deviceNameForm: UntypedFormGroup;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.deviceNameForm = new UntypedFormGroup({
      deviceName: new UntypedFormControl(this.deviceName, Validators.required)
    });
  }

  edit() {
    if (
      this.deviceNameForm.valid &&
      this.deviceNameForm?.get('deviceName')?.value
    ) {
      this.updateName.emit(
        this.deviceNameForm!.get('deviceName')!.value as string
      );
      this.activeModal.close();
    }
  }
}

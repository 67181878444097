<div class="dot-container">

  <img
    *ngFor="let element of elements; let i = index"
    class="img-style"
    [ngClass]="{ 
      'pointer-element': !element.disabled, 
      'not-allowed-element': element.disabled, 
      'not-selected': !isSelected(i)
    }"
    (click)="setControlValue(i)"
    [src]="element.src"
  />

</div>

import { AbstractControl } from "@angular/forms";

/**
 * Check if ra ge is valid (start >= end) and if dateRangeLimit is valid
 * 
 * @param formGroup 
 * @param dateRangeLimit undefined if no limit is required
 * @param errorPropertyName 
 * @returns 
 */
export const dateRangeValidator = (formGroup: AbstractControl, dateRangeLimit?: number, errorPropertyName?: string ) => {
    const dateFrom = formGroup.get('dateFrom')?.value;
    const dateTo = formGroup.get('dateTo')?.value;

    if (!dateFrom || !dateTo) {
        return null;
    }

    const from = dateFrom instanceof Date ? dateFrom : new Date(dateFrom);
    const to = dateTo instanceof Date ? dateTo : new Date(dateTo);

    if (to < from) {
        return { endDateBeforeStartDate: true };
    }

    const diffInDays = (to.getTime() - from.getTime()) / (1000 * 3600 * 24);

    return dateRangeLimit && diffInDays > dateRangeLimit ? { [errorPropertyName!]: true } : null;

}

/**
 * Return iso string for dateFormRange
 * 
 * @param formRangeValue 
 * @returns 
 */
export const getLimitTimes = (formRangeValue: any) => {
    let start = undefined;
    if ( formRangeValue.dateFrom ) {
        start = new Date(
            formRangeValue.dateFrom.year,
            formRangeValue.dateFrom.month - 1,
            formRangeValue.dateFrom.day,
            formRangeValue.hourFrom? formRangeValue.hourFrom.hour : 0,
            formRangeValue.hourFrom? formRangeValue.hourFrom.minute : 0
        );
    }

    let end = undefined;
    if ( formRangeValue.dateTo ) {
        end = new Date(
            formRangeValue.dateTo.year,
            formRangeValue.dateTo.month - 1,
            formRangeValue.dateTo.day,
            formRangeValue.hourTo? formRangeValue.hourTo.hour : 0,
            formRangeValue.hourTo? formRangeValue.hourTo.minute : 0
        );
    }

    return {
        rangeTime: end && start ? Math.abs(+end - +start) / 1000 : undefined,
        startTime: start ? start.toISOString(): undefined,
        endTime: end ? end.toISOString() : undefined
    }

}
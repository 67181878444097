<div class="modal-header">
  <h2>{{ 'MENU.NEW_MENU' | translate }}</h2>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-3" [formGroup]="menuForm">
  <label class="custom-label w-100"
    >{{ 'MENU.NAME' | translate }}
    <input
      type="text"
      class="custom-input"
      formControlName="name"
      [placeholder]="'MENU.NAME' | translate" />
    <app-control-errors id="name" controlName="name"></app-control-errors
  ></label>
</div>
<div class="modal-footer">
  <button
    [disabled]="!menuForm.valid"
    (click)="createNewRecipe()"
    class="button-primary"
  >
    {{ 'GLOBAL.CREATE' | translate }}
  </button>
</div>

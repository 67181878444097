<div class="hso-cooking-step">
  <img src="assets/images/hso_logo_cs.png" />
  <div class="row phases-box">
    <div class="col-lg-12 section-box d-flex align-items-center">
      <div class="icon-box">
        <img class="hso-img" src="assets/images/time.png" alt="Time icona" />
      </div>
      <div class="slider-box">
        <app-slider
          [control]="timeControl"
          [ceil]="timeSetup.ceil"
          [floor]="timeSetup.floor"
          [step]="timeSetup.step"
          [color]="'orange'"
          [time]="timeSetup.time"
          [showButton]="true"
          [firstStep]="10"
        ></app-slider>
      </div>
    </div>
    <div class="col-lg-12 section-box d-flex align-items-center">
      <div class="icon-box">
        <img
          class="hso-img"
          src="assets/images/btn_forno_vapore.png"
          alt="Temperature"
        />
      </div>
      <div class="slider-box">
        <app-slider
          [control]="microwaveControl"
          [ceil]="100"
          [floor]="0"
          [step]="1"
          [color]="'blue'"
          [percentage]="true"
          [showButton]="true"
          [firstStep]="15"
        ></app-slider>
      </div>
    </div>
    <div class="col-lg-12 section-box d-flex align-items-center">
      <div class="icon-box">
        <img src="assets/images/fan.png" alt="Fan" class="hso-img" />
      </div>
      <div class="slider-box">
        <app-slider
          [control]="fanControl"
          [ceil]="fanSetup.ceil"
          [floor]="fanSetup.floor"
          [step]="fanSetup.step"
          [minLimit]="fanSetup.minLimit"
          [color]="'yellow'"
          [percentage]="true"
          [showButton]="true"
          [firstStep]="15"
        ></app-slider>
      </div>
    </div>
  </div>
</div>

<div class="mb-3 d-flex w-100">
  <input
    class="custom-input"
    type="number"
    [formControl]="fieldControlStart"
    placeholder="{{ placeholder }}"
    min=0
    [ngClass]="{'invalid': !fieldControlStart.valid}"
  />
  <input
    class="custom-input"
    type="number"
    [formControl]="fieldControlEnd"
    placeholder="{{ placeholder }}"
    min=0
    [ngClass]="{'invalid': !fieldControlEnd.valid}"
  />
</div>

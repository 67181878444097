<div class="modal-header">
  <h2>
    {{
      (ingredient
        ? 'INGREDIENT.EDIT_INGREDIENT'
        : 'INGREDIENT.ADD_NEW_INGREDIENT'
      ) | translate
    }}
  </h2>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-3" [formGroup]="ingredientFormGroup">
  <label class="custom-label"
    >{{ 'INGREDIENT.NAME' | translate }}
    <input
      type="text"
      class="custom-input"
      formControlName="name"
      [placeholder]="'INGREDIENT.NAME' | translate" />
    <app-control-errors
      id="validate-name"
      controlName="name"
    ></app-control-errors
  ></label>

  <label class="custom-label w-100"
    >{{ 'INGREDIENT.TYPE' | translate }}
    <ng-select
      class="w-100"
      [items]="typeList"
      formControlName="type"
      classList="custom-input"
      bindLabel="name"
      [clearable]="false"
      [searchable]="false"
      [placeholder]="'INGREDIENT.TYPE' | translate"
    >
    </ng-select>
    <app-control-errors
      id="validate-type"
      controlName="type"
    ></app-control-errors
  ></label>

  <div class="row">
    <div class="col-12">
      <label class="custom-label"
        >{{ 'INGREDIENT.UM' | translate }}
        <ng-select
          class="w-100"
          [items]="umList"
          formControlName="um"
          classList="custom-input"
          bindValue="id"
          bindLabel="name"
          [clearable]="false"
          [searchable]="false"
          [placeholder]="'INGREDIENT.UM' | translate"
        >
        </ng-select>
        <app-control-errors
          id="validate-um"
          controlName="um"
        ></app-control-errors
      ></label>

      <!-- <label class="custom-label"
        >{{ 'INGREDIENT.FOODCOST' | translate }}
        <input
          type="text"
          class="custom-input"
          formControlName="foodcost"
          [placeholder]="'INGREDIENT.FOODCOST' | translate" />
        <app-control-errors
          id="validate-foodcost"
          controlName="foodcost"
        ></app-control-errors
      ></label> -->
    </div>
    <div class="col-12">
      <label class="custom-label"
        >{{ 'INGREDIENT.DENSITY' | translate }}
        <input
          type="number"
          min="0"
          class="custom-input"
          formControlName="density"
          [placeholder]="'INGREDIENT.DENSITY' | translate" />
        <app-control-errors
          id="validate-density"
          controlName="density"
        ></app-control-errors
      ></label>

      <label class="custom-label"
        >{{ 'INGREDIENT.CALORIES' | translate }}
        <input
          type="number"
          min="0"
          class="custom-input"
          formControlName="calories"
          [placeholder]="'INGREDIENT.CALORIES' | translate" />
        <app-control-errors
          id="validate-calories"
          controlName="calories"
        ></app-control-errors
      ></label>
    </div>
  </div>
  <label class="custom-label"
    >{{ 'INGREDIENT.ALLERGENS' | translate }}
    <ng-select
      [items]="allergensList"
      formControlName="allergens"
      classList="custom-input"
      bindLabel="name"
      [clearable]="false"
      [multiple]="true"
      class="tag w-100"
      [searchable]="true"
      [placeholder]="'INGREDIENT.ALLERGENS' | translate"
    >
    </ng-select>
    <app-control-errors
      id="validate-allergens"
      controlName="allergens"
    ></app-control-errors
  ></label>
</div>
<div class="modal-footer">
  <button
    *ngIf="!ingredient"
    [disabled]="!ingredientFormGroup.valid"
    (click)="createNewIngredient()"
    class="button-primary"
  >
    {{ 'GLOBAL.CREATE' | translate }}
  </button>
  <button
    *ngIf="ingredient"
    [disabled]="!ingredientFormGroup.valid"
    (click)="editIngredient()"
    class="button-primary"
  >
    {{ 'GLOBAL.EDIT' | translate }}
  </button>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from 'src/app/api/nbk/api/menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  showModal: boolean = false;

  @Input() menu: any;

  @Output() onDelete: EventEmitter<number> = new EventEmitter();

  constructor(private router: Router, private menuService: MenuService) {}

  ngOnInit(): void {}

  toggleModalAction() {
    this.showModal = !this.showModal;
  }

  openDetail(menu: any, edit: boolean) {
    if (edit) {
      this.router.navigate(['menu', 'edit', this.menu.id]);
    }
  }

  deleteMenu(menu: any) {
    this.onDelete.emit(menu.id);
  }
}

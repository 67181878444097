import { Component, Input, OnInit } from '@angular/core';
import { DeviceBoDtoNBK } from 'src/app/api/nbk';

@Component({
  selector: 'app-device-type',
  templateUrl: './device-type.component.html',
  styleUrls: ['./device-type.component.scss']
})
export class DeviceTypeComponent implements OnInit {
  @Input() device: DeviceBoDtoNBK;
  
  constructor( ) {  }

  ngOnInit(): void { }


}

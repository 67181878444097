import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RecipeBoDtoNBK } from 'src/app/api/nbk';

@Component({
  selector: 'app-phase-tab-hso',
  templateUrl: './phase-tab-hso.component.html',
  styleUrls: ['./phase-tab-hso.component.scss']
})
export class PhaseTabHsoComponent implements OnInit {
  @Input() fgroup: UntypedFormGroup;
  @Input() cookingMode: RecipeBoDtoNBK.CookingModeEnum;
  @Input() deviceModel: RecipeBoDtoNBK.DeviceModelEnum;
  @Input() tempUnit: 'C' | 'F';

  timeSetup = {
    ceil: 36000,
    floor: 0,
    step: 5,
    time: true
  };
  fanSetup = {
    ceil: 100,
    floor: 0,
    step: 1,
    minLimit: 0
  };

  get microwaveControl() {
    return this.fgroup.controls['magnetron'] as UntypedFormControl;
  }
  get timeControl() {
    return this.fgroup.controls['time'] as UntypedFormControl;
  }
  get fanControl() {
    return this.fgroup.controls['fanSpeed'] as UntypedFormControl;
  }

  constructor() {}

  ngOnInit(): void {}
}

import { IngredientBoDtoNBK, RecipeBoDtoNBK, RecipeDtoNBK, UserKeycloakDtoNBK } from "../api/nbk";
import { AuthService } from "./auth.service";

export type IcsEntity = IngredientBoDtoNBK | RecipeBoDtoNBK | RecipeDtoNBK

export const canHandleIcsItem =  (item: IcsEntity, authService: AuthService) => {
    return item.ics && ( authService.permissions.CHEF_LAINOX || authService.permissions.SUPERUSER ) 
        || !item.ics && item.user === authService.userId;
}

export const canMarkAsIcs = (item: IcsEntity, authService: AuthService) => {
    return item.ics === false &&
      (authService.permissions.CHEF_LAINOX || authService.permissions.SUPERUSER);
}

export const isSuperuserOrChefLainox = (authService: AuthService) => {
    return authService.permissions.CHEF_LAINOX || authService.permissions.SUPERUSER;
}

export const canServiceUserEdit = (authService: AuthService) => {
    return authService.permissions.SERVICE_ADMIN 
        || authService.permissions.SUPERUSER 
            || authService.permissions.LAINOX_SERVICE_ADMIN;
}

export const getServiceRoles = (roles: UserKeycloakDtoNBK.RolesEnum[] ) => {
    if ( roles ) {
        if ( roles.includes( "LAINOX_SERVICE_ADMIN" ) ) {
            return "LAINOX_SERVICE_ADMIN";
        } else if ( roles.includes( "SERVICE_ADMIN" ) ) {
            return "SERVICE_ADMIN";
        } else if ( roles.includes( "LAINOX_SERVICE" ) ) {
            return "LAINOX_SERVICE";
        } else if ( roles.includes( "SERVICE" ) ) {
            return "SERVICE";
        } 
    }
    
    return undefined;
}

export const getServiceRoleList = ( authService: AuthService) => {
    if ( authService.permissions.LAINOX_SERVICE_ADMIN ) {
        return [
            "LAINOX_SERVICE_ADMIN",
            "LAINOX_SERVICE",
            "SERVICE_ADMIN",
            "SERVICE"
        ];
    } else if ( authService.permissions.LAINOX_SERVICE ) {
        return [
            "LAINOX_SERVICE",
            "SERVICE_ADMIN",
            "SERVICE"
        ];
    } else if ( authService.permissions.SERVICE_ADMIN) {
        return [
            "SERVICE_ADMIN",
            "SERVICE"
        ];
    }    
    
    return [];

}
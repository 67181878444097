<div class="modal-header">
  <h2>{{ title }}</h2>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-3">
  {{ message }}
</div>
<div class="modal-footer">
  <button class="button-secondary" (click)="cancel()">
    {{ 'GLOBAL.CANCEL' | translate }}
  </button>
  <button class="button-primary" (click)="confirm()">
    {{ 'GLOBAL.CONFIRM' | translate }}
  </button>
</div>
